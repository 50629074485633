import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import authReducer from '../authReducer'; // Adjust the path based on your folder structure
//import rootReducer from './reducers'; // Assuming you have a file named 'reducers.js' in the 'reducers' directory

// Combine your reducers using combineReducers
const combinedReducers = combineReducers({
  auth: authReducer, // Assuming authReducer is imported and defined correctly
  // Add other reducers here if needed
});

const store = createStore(combinedReducers, applyMiddleware(thunk));

export default store;
