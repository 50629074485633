import styled from 'styled-components';

const ToggleButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.4rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.primaryVariant};
  }
`;

export { ToggleButton };