import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { baseURL, API_URL } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import { MdCancel, MdAdd, MdClose } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';
import { IoMdOptions } from "react-icons/io";
import styled, { keyframes } from 'styled-components';
import Spinner from './Spinner';

const BgBig = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

const MainContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const StyledH2 = styled.h2`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
`;

const CancelIcon = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Icon = styled.div`
  cursor: pointer;
`;

const CenterSearchAndCreateButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const NoPostsMessage = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  margin-top: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background2};
  @media (max-width: 768px) {
    //flex-direction: column;
    align-items: center;
  }
`;

const ThumbnailDiv = styled.div`
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

const PostDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

const PostTitle = styled.h3`
  font-size: 20px;
  margin: 0;
  color: ${(props) => props.theme.colors.text};
`;

const PostAnalysis = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  p {
    margin: 0;
  }
  time {
    font-size: 14px;
    color: ${(props) => props.theme.colors.subText};
  }
`;

const StatusDiv = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) => {
    if (props.status === 'public') return '#d4edda';
    if (props.status === 'private') return '#fff3cd';
    if (props.status === 'pending') return '#fff3cd';
    if (props.status === 'scheduled') return '#f8d7da';
    return '#f0f0f0';
  }};
  color: ${(props) => {
    if (props.status === 'public') return '#155724';
    if (props.status === 'private') return '#856404';
    if (props.status === 'pending') return '#856404';
    if (props.status === 'scheduled') return '#721c24';
    return '#333';
  }};
`;

const ActiveStatusAndEllipsesDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const AdminActiveStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) => {
    if (props.adminStatus === 'active') return '#d4edda';
    if (props.adminStatus === 'inactive') return '#fff3cd';
    if (props.adminStatus === 'blocked') return '#f8d7da';
    return '#f0f0f0';
  }};
  color: ${(props) => {
    if (props.adminStatus === 'active') return '#155724';
    if (props.adminStatus === 'inactive') return '#856404';
    if (props.adminStatus === 'blocked') return '#721c24';
    return '#333';
  }};
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledP = styled.p`
  color: ${(props) => props.theme.colors.text};
`;

const StyledTime = styled.time`
  color: ${(props) => props.theme.colors.text};
`;

const Ellipses = styled.div`
  cursor: pointer;
  font-size: 24px;
  font-weight: 800;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: #007bff;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  background: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.text};
  overflow: hidden;
  z-index: 10;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.colors.dark};
  color: ${props => props.theme.colors.text};
  padding: 10px;
`;

const CloseIcon = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: ${(props) => props.theme.colors.danger};
  &:hover {
    color: #ff0000;
  }
`;

const DropdownOption = styled.div`
  padding: 10px 15px;
  background: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: ${props => (props.postIsActive ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 6px;
    height: 6px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

export {Container, Card, Title, ThumbnailDiv, PostDetailsDiv, PostTitle, AudioIndicator, ActiveStatusAndEllipsesDiv, Actions, AdminActiveStatusDiv, StatusDiv, StyledP, 
  StyledTime, StyledH1, CancelIcon, StyledAddButton, Ellipses, DropdownHeader, DropdownMenu, DropdownOption, CloseIcon, PostAnalysis, MainContainer, PaginationWrapper, Pagination, PaginationButton, PageNumber};


const ProducerAllPosts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(50);
  const [editingPostId, setEditingPostId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownPostId, setDropdownPostId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const toggleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      const token = await getValidToken(navigate, true); 
      if (token) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          params: { searchQuery, currentPage, perPage },
        };
        const response = await axios.get(`${API_URL}/all/posts/producer`, config);
        const { totalCount, posts } = response.data;
        setTotalCount(totalCount);
        setPosts(posts);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching posts:', error);
      }
    }
    };
    fetchPosts();
  }, [searchQuery, currentPage, perPage, navigate]);

  const handleDropdownClick = (postId) => {
    if (dropdownPostId === postId && isDropdownOpen) {
      setIsDropdownOpen(false);
      setDropdownPostId(null);
    } else {
      setIsDropdownOpen(true);
      setDropdownPostId(postId);
    }
  };

  const handleCloseDropdown = () => {
    setDropdownPostId(null);
    setIsDropdownOpen(false);
  };

  const handleEditPost = (postId) => {
    setDropdownPostId(null);
    setIsDropdownOpen(false);
    navigate(`edit/post/${postId}`);
  };

  const handleDelete = async (postId) => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await axios.delete(`${API_URL}/posts/${postId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }); 
        setPosts(posts.filter(post => post.id !== postId)); // Update the posts state
        setIsDropdownOpen(false);
      } catch (error) {
        setIsDropdownOpen(false);
        console.error('Error deleting post:', error);
      }
    }
  }
  };

  const handleUpdatePostStatus = (postId) => {
    setDropdownPostId(null);
    setIsDropdownOpen(false);
    // Handle post status update
  };

  if (isLoading) {
    return (
      <MainContainer>
        <Spinner />
      </MainContainer>
    );
  }

  const handleCloseEdit = () => {
    setEditingPostId(null);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handleSearchVisible = () => {
    setSearchQuery('');
    setSearchVisible(!searchVisible)
  }


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNextPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalCount / perPage)));
  };

  const handlePreviousPage = () => {
    scrollToTop();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    scrollToTop();
    setCurrentPage(pageNumber);
  };

  const handleAddPost = () => {
    navigate('/upload');
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const formatTimeAgo = (createdDate, scheduledAt = null) => {
    const currentDate = new Date();
  
    // Check if the post is scheduled for the future
  if (scheduledAt) {
    const scheduledDate = new Date(scheduledAt);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    if (scheduledDate > currentDate) {
      return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
    }
  }

  
    const timeDifference = currentDate - new Date(createdDate);
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);
  
    if (yearsDifference > 0) {
      return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
    } else if (monthsDifference > 0) {
      return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
    } else if (daysDifference > 0) {
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
    }
  };
  

  const getDisplayStatus = (status, scheduledAt) => {
    const now = new Date();
    const scheduledDate = new Date(scheduledAt);
  
    // If the post is scheduled and the scheduled date is in the past
    if (status === 'scheduled' && scheduledDate <= now) {
      return 'public';
    }
  
    // Otherwise return the original status
    return status;
  };
  

  return (
    <BgBig>
      <Container>
        <Title>
          <StyledH1>My Posts</StyledH1>
          <StyledH2>Total: {totalCount}</StyledH2>
          <CancelIcon>
            <StyledAddButton onClick={handleGoBack}>
              <MdCancel style={{ color: 'black', fontSize: '24px' }} />
            </StyledAddButton>
          </CancelIcon>
        </Title>

        {searchVisible && (
          <InputBox>
            <StyledInput
              label="Search"
              value={searchQuery}
              placeholder="Search..."
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
            />
            <Icon>
              <FaSearch variant="contained" style={{ color: 'black', fontSize: '24px' }} onClick={handleSearch} />
            </Icon>
          </InputBox>
        )}
        <CenterSearchAndCreateButton>
        <AddButton onClick={handleAddPost}>
            <MdAdd style={{ color: 'white', fontSize: '24px', fontWeight: '500' }} /> New Post
          </AddButton>
          <AddButton onClick={handleSearchVisible}>
            {searchVisible ? 'Hide Search' : 'Show Search'}
          </AddButton>
        </CenterSearchAndCreateButton>

        {posts.length === 0 ? (
          <NoPostsMessage>No posts available. Create a new one!</NoPostsMessage>
        ) : (
          posts.map((post) => (
            <Card key={post.id}>
              <Link to={`edit/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ThumbnailDiv>
                  {post.thumbnail && <img src={`${baseURL}${post.thumbnail}`} alt="Thumbnail" />}
                </ThumbnailDiv>
              </Link>
              <PostDetailsDiv>
                <Link to={`edit/post/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <PostTitle>{post.title}</PostTitle>
                </Link>
                <PostAnalysis>
                  <StyledP>N{post.price}</StyledP>
                  <StatusDiv status={getDisplayStatus(post.status, post.scheduled_at)}>{getDisplayStatus(post.status, post.scheduled_at)}</StatusDiv>
                  <StyledP>{post.views}</StyledP>
                  <StyledTime>{formatTimeAgo(post.created_at, post.scheduled_at)}</StyledTime>
                  <StyledP>{post.totalSold}</StyledP>
                </PostAnalysis>
                <ActiveStatusAndEllipsesDiv>
                  <AdminActiveStatusDiv adminStatus={post.active_status}>
                    <p>{post.active_status}</p> 
                    {post.active_status === 'active' && <AudioIndicator postIsActive={post.active_status}/>}
                  </AdminActiveStatusDiv>
                  <Ellipses onClick={() => handleDropdownClick(post.id)}>
                    <IoMdOptions />
                  </Ellipses>
                </ActiveStatusAndEllipsesDiv>
              </PostDetailsDiv>
              <Actions ref={dropdownRef}>
                {isDropdownOpen && dropdownPostId === post.id ? (
                  <DropdownMenu onClick={(e) => e.stopPropagation()}>
                    <DropdownHeader>
                      <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                    </DropdownHeader>
                    <DropdownOption onClick={() => handleEditPost(post.id)}>Edit</DropdownOption>
                    <DropdownOption onClick={() => handleDelete(post.id)}>Delete</DropdownOption>
                    <DropdownOption onClick={() => handleUpdatePostStatus(post.id)}>Update Status</DropdownOption>
                  </DropdownMenu>
                ) : null}
              </Actions>
            </Card>
          ))
        )}

        {isDropdownOpen && <Overlay />}

      <PaginationWrapper>
        <Pagination>
          <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </PaginationButton>
          {Array.from({ length: Math.ceil(totalCount / perPage) }, (_, index) => (
            <PageNumber
              key={index + 1}
              onClick={() => handlePageClick(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </PageNumber>
          ))}
          <PaginationButton onClick={handleNextPage} disabled={currentPage === Math.ceil(totalCount / perPage)}>
            Next
          </PaginationButton>
        </Pagination>
      </PaginationWrapper>

      </Container>
      </BgBig>
  );
};

export default ProducerAllPosts;
