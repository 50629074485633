import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import incrementViewCount from '../utils/incrementView';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import AddFundModel from './AddFundModel';
import Header from './Header';
import WelcomeAndBalance from './WelcomeAndBalance';
import ServicesCards from './Services/ServicesCards';
import Footer from './Footer';
import Menu from './Menu';


const Container = styled.div`
  min-height: 100vh;

`;

const MenuSection = styled.div`

`;

const AddFundModelDiv = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.text};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1100;
  padding: 10px;
  width: 100%;
  overflow: auto;
`;

const Home = () => {
  const [showMenu, setShowMenu] =  useState(false);
  const [addFundModelOpen, setAddFundModelOpen] = useState(false);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const HeaderRef = useRef(null);

  useEffect(() => {
    const scrollToTop = () => {
      HeaderRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    if (effectRan.current) return; 

    const incrementView = async () => {
      const token = await getValidToken(navigate, true);
      if (!token) return;
      try {
        await incrementViewCount('homepage', 'homepage', navigate);
        console.log('View incremented and session storage updated');
      } catch (error) {
        console.error('Error incrementing view count:', error);
      }
    };

    incrementView();
    effectRan.current = true; 

    // Clean-up function
    return () => {
      console.log('Cleaning up');
    };
  }, [navigate]);
  

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  }

  const handleAddFundModel = () => {
    setAddFundModelOpen(!addFundModelOpen);
  };

  return (
    <Container ref={HeaderRef}>
      <Header />
      <WelcomeAndBalance showAddFund={handleAddFundModel}/>
      <ServicesCards showAddFund={handleAddFundModel}/>
      
      {showMenu && (
        <MenuSection>
          <Menu show={handleShowMenu} onClose={handleShowMenu}/>
        </MenuSection>
      )}
      <Footer show={handleShowMenu} />
      {addFundModelOpen && (
        <AddFundModelDiv>
          <AddFundModel toggleModal={handleAddFundModel} isFromModal={true}/>
        </AddFundModelDiv>
      )}
    </Container>
  );
}

export default Home;
