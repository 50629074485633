import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {API_URL} from '../config';
import axios from 'axios';

import { Container, Logo, LoadingIcon, SuccessIcon, ErrorIcon, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton } from './Admin/styles'; // Import Card and FormControl directly
import { MdEmail } from 'react-icons/md';
import logoImg from '../assets/Arewamega.png';

const VerifyEmail = () => {
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendSuccessfull, setSendSuccessfull] = useState(false);
  const [error, setError] = useState('');

  const [verificationCode, setVerificationCode] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate page content rendering
    const timeout = setTimeout(() => {
      setScreenLoading(false); // Hide loading spinner after 2 seconds (adjust timing as needed)
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once after component mounts

  useEffect(() => {
    // Retrieve userEmail from local storage
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
      setSendSuccessfull(false);
      setError(false);

    e.preventDefault();

    try {
      const res = await axios.post(`${API_URL}/users/verify-email`, { email, verificationCode });

      if (res.status === 200) {
        console.log('Email verified successfully');
        setSendSuccessfull(true);
        setLoading(false);

        localStorage.removeItem('userEmail');

      //  navigate('/login'); // Navigate to the home page upon successful verification

        setTimeout(() => {
          setSendSuccessfull(false);
        //  setLoading(false);
          navigate('/login');
      }, 2000); // Delay navigation for 2 seconds after showing success message

      } else {
        console.error('Error verifying email:', res.data || 'Unknown error');
        setError('Error verifying email:', res.data);
        setLoading(false);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
        // Handle error response from server
      }
    } catch (err) {
      console.error('Error verifying email:', err.response?.data || err.message);
      setError('Error verifying email:', err.response?.data || err.message);
        setLoading(false);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
      // Handle error response from server
    }
  };

  const handleResend = async () => {
    setLoading(true);
    if (resendCount >= 3) {
      //alert('You have reached the resend limit. Please contact admin or wait until tomorrow.');
      setError('You have reached the resend limit. Please contact admin or wait until tomorrow.');
        setLoading(false);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
      return;
    }

    try {
      setResendDisabled(true);
      const res = await axios.post(`${API_URL}/users/resend-verification-code`, { email });
      console.log(res.data);
      setLoading(false);
      setResendCount(resendCount + 1);
      setTimeout(() => setResendDisabled(false), 10000); // Enable resend after 10 seconds
    } catch (err) {
      console.error('Error resending verification code:', err.response?.data || err.message);
      setError(err.message);
        setLoading(false);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
      // Handle error response from server
    }
  };

  //useEffect(() => {
  //  setVerificationCode('');
  //}, []);

  return (
    <Container>
     {screenLoading ? (
                <LoadingIcon />
              ) : (   
      <Card>
      <Logo src={logoImg} alt="Logo" />
      <TitleContainer>
          <Title>Verify Email</Title>
          <p>Enter the verification code sent to {email}</p>
        </TitleContainer>
        {/* Display error or success message if login fails */}
        {sendSuccessfull && <p style={{ color: 'green', backgroundColor: 'black', padding: '5px' }}>Verified successful!</p>}
        {error && <p style={{ color: 'black' }}>{error}</p>}
    
      <form onSubmit={handleSubmit}>
        {/* <label htmlFor="verificationCode">Enter Verification Code:</label> */}
        <InputBox>
            <Icon>
              <MdEmail style={{ color: 'black', fontSize: '24px' }} />
            </Icon>
        <StyledInput
          type="text"
          id="verificationCode"
          name="verificationCode"
          value={verificationCode}
          onChange={handleChange}
        />
        <Label>Enter Verification Code:</Label>
      </InputBox>

      <StyledButton type="submit" disabled={loading || resendDisabled || sendSuccessfull}>
          {sendSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Verify'}
          {loading && <LoadingIcon />}
        </StyledButton>

        {/* <button type="submit" disabled={resendDisabled} style={styles.button} >Verify</button> */}
      </form>
      {/* <button onClick={handleResend} style={resendDisabled ? styles.disabledButton : styles.button}>Resend Verification Code</button> */}

      <StyledButton type="submit" onClick={handleResend} disabled={loading || resendDisabled || sendSuccessfull}>
          {sendSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Resend Verification Code'}
          {loading && <LoadingIcon />}
        </StyledButton>

        </Card>
      )} 
    </Container>
  );
};

export default VerifyEmail;
