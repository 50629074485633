import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
`;

const OuterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  //height: 100vh; /* Ensure the div takes up the full viewport height */
`;

const CircleContainer = styled.div` 
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;

  input[type='file'] {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .icon {
    position: relative;
    bottom: 5px;
    right: 5px;
    color: #999;
    font-size: 24px;
  }
`;

export { Container, OuterDiv, CircleContainer };