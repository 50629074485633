import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import { SoundProvider } from './SoundContext';
import AuthCheck from './utils/AuthCheck';
import UserAuthCheck from './utils/UserAuthCheck';
import GlobalStyles from './GlobalStyles';
import ConsentBanner from './components/ConsentBanner';
import Home from './components/Home'; 
import Register from './components/Register';
import Login from './components/Login';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import VerifyVerificationCode from './components/VerificationCodeForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import VerifyEmail from './components/VerifyEmail';
import Logout from './components/Logout';
import UserDetails from './components/UserDetails';
import Dashboard from './components/Admin/Dashboard';
import ForeignTransferReceipts from './components/Admin/ForeignTransferReceipts';
import Users from './components/Admin/Users'; 

import Airtime from './components/Services/Airtime';
import Data from './components/Services/Data';
import Transactions from './components/Services/Transactions';
import PlaceHolder from './components/Services/PlaceHolder';
import Profile from './components/Profile';
import LissafinRayuwa from './components/LissafinRayuwa';

import AdminLogin from './components/Admin/AdminLogin';
import AdminWalletManagement from './components/Admin/AdminWalletManagement';
import AdminTransactions from './components/Admin/Transactions';
import DataPlans from './components/Admin/DataPlans';
import ContactUs from './components/ContactUs';
//import Notifications from './components/Notifications';

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';


export const ThemeContext = createContext(); 



const App = () => {
  const [theme, setTheme] = useState(() => {
  return localStorage.getItem('theme') || 'light';
  });

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <Router>
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
    <SoundProvider>
    <GlobalStyles />  
      <div className="App">
        <ConsentBanner />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/verify-code" element={<VerifyVerificationCode />} />
          <Route path="/update-password" element={<UpdatePasswordForm />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/ContactUs" element={<ContactUs />} />

          <Route 
            path="/*" 
            element={
            <UserAuthCheck>
              <Routes>
                {/* <Route path="/post/:postId" element={<PostDetails />} /> */}
                <Route path="/airtime-purchase" element={<Airtime />} />
                <Route path="/data-purchase" element={<Data />} />
                <Route path="/user-details" element={<UserDetails />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/Services" element={<PlaceHolder />} />
                <Route path="/Lissafin-Rayuwa" element={<LissafinRayuwa />} />
                {/* <Route path="Notifications" element={<Notifications />} />            */}
              </Routes>
            </UserAuthCheck>
            }
            />

          <Route
            path="/admin/*"
            element={
              <AuthCheck>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="wallet-management" element={<AdminWalletManagement />} />
                  <Route path="transfer/receipts" element={<ForeignTransferReceipts />} />
                  <Route path="transactions" element={<AdminTransactions />} />
                  <Route path="dataplans" element={<DataPlans />} />
                </Routes>
              </AuthCheck>
            }
          />

          {/* Redirect any unknown routes to Home */}
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </SoundProvider> 
    </ThemeContext.Provider>
    </ThemeProvider>
    </Router>
  );
};

export default App;
