import React, { useState } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import Button from './Button';

const PinConfirmModal = ({ onSubmit, onClose }) => {
    const [pin, setPin] = useState('');
  
    const handlePinChange = (e) => {
      setPin(e.target.value);
    };
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      onSubmit(pin); // Call the parent function with the pin
      // Consider closing the modal only after parent component processes the pin
    };
  
    const handleCloseClick = () => {
      onClose();
    };
  
    return (
      <ModalOverlay onClick={handleCloseClick}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <h2>Confirm PIN</h2>
            <CloseDiv onClick={handleCloseClick}>
            <MdClose size={24} color='#fff'/>
          </CloseDiv>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleFormSubmit}>
              {/* <Label htmlFor="pin">Enter your PIN</Label> */}
              <Input
                type="tel"
                id="pin"
                placeholder='Enter Your Pin'
                value={pin}
                onChange={handlePinChange}
                required
              />
              
            </form>
          </ModalBody>
          <Button onClick={handleFormSubmit}>Confirm</Button>
        </ModalContainer>
      </ModalOverlay>
    );
  };
  
  export default PinConfirmModal;

// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1350;
`;

const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1351;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div`
  margin: 20px 0px;
  color: ${(props) => props.theme.colors.text};
`;

const CloseDiv = styled.div`
  background-color: red;
  border-radius: 10px;
  position: absolute;
  font-weight: 800;
  right: 0;
  margin-right: 20px;
  padding: 1px;
  cursor: pointer;
`;

// const Label = styled.label`
//   display: block;
//   margin-bottom: 8px;
//   font-weight: bold;
//   color: ${(props) => props.theme.colors.text};
// `;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin: 10px 0px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`;

