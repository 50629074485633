import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../utils/useLogout';
import { FaPhone, FaWifi } from "react-icons/fa";
import { IoCard } from "react-icons/io5";
import { MdElectricBolt } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { GiTripleScratches } from "react-icons/gi";
import { MdSwapCalls } from "react-icons/md";
import { MdInstallMobile, MdEmail } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { FaUserSecret } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";


const ServicesContainer = styled.div`
    background-color: ${(props) => props.theme.colors.background2};
    border-radius: 15px;
    color: ${(props) => props.theme.colors.text};
    padding: 10px;
    margin-top: -15px;
    //margin: 15px 0px;
`;

const FieldDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin: 10px 0px;
`;

const ItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 5px;


    h4 {
        color: ${(props) => props.theme.colors.text};
    }
`;

const IconDiv = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 7px;
    color: ${(props) => props.theme.colors.text};
    padding: 5px;
`;

const ParentPadding = styled.div`
    padding: 20px;
`;

const ServicesCards = ({showAddFund}) => {
    const logoutUser = useLogout();
    const navigate = useNavigate();

    const handleLogout = () => {
        logoutUser();
      };

  return (
    <>
    <ParentPadding>
    <ServicesContainer>
        <FieldDiv>
            <ItemDiv onClick={() => {navigate('/airtime-purchase')}}>
                <IconDiv><FaPhone size={24} /></IconDiv>
                <h4>Airtime</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/data-purchase')}}>
                <IconDiv><FaWifi size={24} /></IconDiv>
                <h4>Data</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><IoCard size={24} /></IconDiv>
                <h4>Data Card</h4>
            </ItemDiv>
        </FieldDiv>

        <FieldDiv>
            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><MdElectricBolt size={24} /></IconDiv>
                <h4>Electricity</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><PiStudentBold size={24} /></IconDiv>
                <h4>Exam Pins</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><PiTelevisionSimpleBold size={24} /></IconDiv>
                <h4>Cable Tv</h4>
            </ItemDiv>
        </FieldDiv>

        <FieldDiv>
            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><GiTripleScratches size={24} /></IconDiv>
                <h4>Recharge Card</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><MdSwapCalls size={24} /></IconDiv>
                <h4>Airtime Swap</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><MdInstallMobile size={24} /></IconDiv>
                <h4>Donwload App</h4>
            </ItemDiv>
        </FieldDiv>

        <FieldDiv>
            <ItemDiv onClick={() => {navigate('/transactions')}}>
                <IconDiv><FaHistory size={24} /></IconDiv>
                <h4>History</h4>
            </ItemDiv>

            <ItemDiv onClick={showAddFund}>
                <IconDiv><IoIosAddCircle size={24} /></IconDiv>
                <h4>Add Fund</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/profile')}}>
                <IconDiv><FaUser size={24} /></IconDiv>
                <h4>Profile</h4>
            </ItemDiv>
        </FieldDiv>

        <FieldDiv>
            <ItemDiv onClick={() => {navigate('/ContactUs')}}>
                <IconDiv><MdEmail size={24} /></IconDiv>
                <h4>Contact</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/Services')}}>
                <IconDiv><FaUserSecret size={24} /></IconDiv>
                <h4>Agent</h4>
            </ItemDiv>

            <ItemDiv onClick={handleLogout}>
                <IconDiv><HiOutlineLogout color='maroon' size={24} /></IconDiv>
                <h4>Logout</h4>
            </ItemDiv>
        </FieldDiv>

    </ServicesContainer>
    </ParentPadding>
    <div style={{height: '70px'}}/>
    </>
  );
}

export default ServicesCards;