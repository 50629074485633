import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();


 const logoutUser = () => {
    console.log('Logging out user...');
    Cookies.remove('jwtToken', { path: '/' });
    Cookies.remove('refreshToken', { path: '/' });
    console.log('Tokens removed:', { jwtToken: Cookies.get('jwtToken'), refreshToken: Cookies.get('refreshToken') });
    navigate('/login');
  };

  return logoutUser;
};

export default useLogout;


