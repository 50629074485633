import React from 'react';
import styled, { keyframes } from 'styled-components';
import ArewaLogo from '../assets/Arewamega.png';

const bubbleZoom = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5); /* Adjust to how much you want it to zoom */
  }
`;

const SpinnerWrapper = styled.div`
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-image: url(${ArewaLogo});
  background-size: cover;
  background-position: center;
  animation: ${bubbleZoom} 1.4s infinite ease-in-out;
`;

const SpinnerArewaLogo = () => {
  return <SpinnerWrapper />;
};

export default SpinnerArewaLogo;
