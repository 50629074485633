import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import TransactionReceipt from './TransactionsReceipt';
import Header from '../Header';
import Footer from '../Footer'; 
import Menu from '../Menu';
import SpinnerArewaLogo from '../SpinnerArewaLogo';
import { FaPhone, FaWifi, FaMoneyCheckAlt } from "react-icons/fa";
import { FaCircleChevronDown } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { IoMdOptions } from "react-icons/io";
import { API_URL } from '../../config';

const SpinnerArewaLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transaparent;
  min-height: 100vh;
`;

const TransactionsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const MenuSection = styled.div``;

const TransactionCard = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
`;

const ServiceTypediv = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 15px;
    padding: 8px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
    padding: 5px;
  }

  @media (max-width: 290px) {
    margin-top: 5px;
    padding: 3px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 8px;
  }

  @media (max-width: 480px) {
    gap: 5px;
  }

  @media (max-width: 290px) {
    gap: 3px;
  }

  .active {
    color: #28a745;
    font-weight: bold;
  }
`;

const PageNumber = styled.div`
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.background2};
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 0.9rem;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 768px) {
    padding: 7px 10px;
    margin: 0 3px;
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    padding: 5px 8px;
    margin: 0 2px;
    font-size: 0.8rem;
  }

  @media (max-width: 290px) {
    padding: 4px 6px;
    margin: 0 1px;
    font-size: 0.75rem;
  }
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 0.9rem;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 7px 10px;
    margin: 0 3px;
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    padding: 5px 8px;
    margin: 0 2px;
    font-size: 0.8rem;
  }

  @media (max-width: 290px) {
    padding: 4px 6px;
    margin: 0 1px;
    font-size: 0.75rem;
  }
`;

const SearchAndFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }
  button {
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SearchAndFilterIconDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text};

  input {
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }

`;

const Transactions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const maxVisiblePages = 5; 
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(20); 
  const transactionsListRef = useRef(null);

  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const token = await getValidToken(navigate, false);
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/admin/getAlltransactions`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { startDate, endDate, search, page: currentPage, limit: perPage }, // Pass params to API
          withCredentials: true
        });
        setTransactions(response.data.transactions);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching transactions:', error.response?.data || error.message);
      } finally {
        setIsLoading(false);
      }
    }
  }, [search, startDate, endDate, currentPage, perPage, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData, navigate, search, startDate, endDate, currentPage]); // Fetch data when inputs or page change

  const scrollToTop = () => {
    transactionsListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleCardClick = (transaction) => {
    setTransactionDetails(transaction);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    scrollToTop();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const pad = (num) => (num < 10 ? `0${num}` : num);
  
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    return `${day}/${month}/${year} ${pad(hours)}:${minutes}:${seconds} ${ampm}`;
  };

  const generateVisiblePageNumbers = () => {
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  if (isLoading) {
    return (
        <SpinnerArewaLogoDiv>
        <SpinnerArewaLogo />
        </SpinnerArewaLogoDiv>
    )
  }

  return (
    <>
      <Header />
      <TransactionsContainer ref={transactionsListRef}>
      <SearchAndFilter>
      <SearchAndFilterIconDiv>
          <Input
            type="text"
            placeholder="Search by ref, phone, network"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div onClick={handleShowFilter}><IoMdOptions size={30} width={100} height={100} /></div>
        </SearchAndFilterIconDiv>
        {showFilter && (
          <>
          <Input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <Button onClick={fetchData}>Search</Button>
          </>
        )}
        </SearchAndFilter>  
  
        {transactions.map((transaction) => (
          <TransactionCard
            key={transaction.transaction_id}
            onClick={() => handleCardClick(transaction)}
          >
            {transaction.service_type.includes('Data') ? (
              <ServiceTypediv>
              <FaWifi size={24} />
              <h3>{transaction.service_type}</h3>
              </ServiceTypediv>
            ) : transaction.network.includes('Monnify') ? (
              <ServiceTypediv>
              <FaMoneyCheckAlt size={24} />
              <h3>{transaction.service_type}</h3>
              </ServiceTypediv>
            ) : (
              <ServiceTypediv>
              <FaPhone size={24} />
              <h3>{transaction.service_type}</h3>
              </ServiceTypediv>
            )}
            
            {!transaction.network.includes('Monnify') && <p>Phone: {transaction.phone}</p>}

            <p>
              Status: {transaction.status}{' '}
              {transaction.status === 'success' ? (
                <FaCircleChevronDown color="green" />
              ) : (
                <MdCancel color="red" />
              )}
            </p>
            <p>Amount: ₦{transaction.amount}</p>
            <p>Date: {formatDate(transaction.created_at)}</p>
          </TransactionCard>
        ))}
  
        {transactionDetails && (
          <TransactionReceipt
            transaction={transactionDetails}
            onClose={() => setTransactionDetails(null)}
          />
        )}
      </TransactionsContainer> 

    <PaginationWrapper>
      <Pagination>
        <PaginationButton
          onClick={() => setCurrentPage(1)} 
          disabled={currentPage === 1}
        >
          First
        </PaginationButton>

        <PaginationButton
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>

        {generateVisiblePageNumbers().map((page) => (
          <PageNumber
            key={page}
            onClick={() => handlePageClick(page)}
            className={currentPage === page ? 'active' : ''}
          >
            {page}
          </PageNumber>
        ))}

        <PaginationButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </PaginationButton>

        <PaginationButton
          onClick={() => setCurrentPage(totalPages)} // Jump to last page
          disabled={currentPage === totalPages}
        >
          Last
        </PaginationButton>
      </Pagination>
    </PaginationWrapper>
  
      {showMenu && (
        <MenuSection>
          <Menu show={handleShowMenu} onClose={handleShowMenu} />
        </MenuSection>
      )}
  
      <div style={{ height: '70px' }} />
      <Footer show={handleShowMenu} />
    </>
  );  
};

export default Transactions;
