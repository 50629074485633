import styled from 'styled-components';
import { lightTheme, darkTheme } from '../../theme';
import { FaEllipsisH, FaCheck, FaTimes  } from 'react-icons/fa';

// Card component styled with theme variables
// const Container = styled.div `
//   width: 100%;
//   max-width: 100%;
//   height: 100vh;
//   padding: 20px;
//   //margin: 0 auto;
//   background-color: black;

// `;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${props => props.theme.colors.text};
`;

const LoadingIcon = styled(FaEllipsisH)`
  //position: absolute;
  //top: 50%;
  //right: 10px;
  //transform: translateY(-50%);
  animation: spin 1.4s infinite linear;
  margin-left: 5px; /* Adjust margin-left as needed */

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const SuccessIcon = styled(FaCheck)`
  color: green;
  margin-left: 5px; /* Adjust margin-left as needed */
`;

const ErrorIcon = styled(FaTimes)`
  color: red;
  margin-left: 5px; /* Adjust margin-left as needed */
`;

const Spinner = styled.div`
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
`;

const Logo = styled.img`
  position: absolute;
  top: 30px; /* Adjust the top position to move the logo up */
  left: 50%;
  transform: translateX(-50%);
  width: 100px; /* Adjust the width of the logo */
  height: 100px; /* Adjust the height of the logo */
  border-radius: 50%; /* Make the logo circular */
  border: 2px solid #fff; /* Optional: Add a border to the logo */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow */
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
margin-top: 45px; /* Adjust the top margin of the title */
text-align: center;
`;

const Card = styled.div`
  background: rgba(32, 99, 135, 0.9);
  margin: auto;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  align-items: center;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  //width: 460px;
  max-width: 430px;
  //height: 490px; /* Add the desired height value here */
  color: white;
  //color: ${({ theme }) => theme.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  h1 {
    color: black;
  }
`;

const InputBox = styled.div`
display: flex;
justify-content: center;
position: relative;
max-width: 355px;
height: 50px;
border: 1px solid rgba(255, 255, 255, 0.5);
margin-bottom: 25px;

&:nth-child input:valid ~ span,
&:nth-child input:focus ~ span {
  background: #00dfc4;
  color: black;
  border-radius: 2px;
}

input:valid ~ span,
input:focus ~ span {
  color: black;
  transform: translateX(10px) translateY(-14px);
  font-size: 15px;
  padding: 0 10px;
  background: #32a852;
  border-left: 1px solid #00dfc4;
  border-right: 1px solid #00dfc4;
  border-radius: 5px;
}
`;


const Label = styled.span`
position: absolute;
left: 0;
padding: 10px;
pointer-events: none;
font-size: 20px;
font-weight: 400;
color: rgba(255, 255, 255, 0.9);
transition: 0.3s ease;
`;

// FormControl component styled with theme variables
const StyledInput = styled.input`
width: 100%;
padding: 10px;
background: transparent;
border-radius: 5px;
outline: none;
color: white;
font-size: 20px;
transition: 0.3s;

&:valid,
&:focus {
  border: 2px solid #00dfc4;
}
`;

const Icon = styled.i`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: black;
  border-radius: 6px;
  color: white;
  margin-top: 20px;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 800;
  text-align: center;
`;

export { Container, LoadingIcon, SuccessIcon, ErrorIcon, Spinner, Logo, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton };
