import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const ErrorMessageModal = ({ errorMessage, onClose }) => {
  return (
    <ModalContainer>
        <HeaderSection>
            <div style={{width: '100%', color: 'red', fontWeight: 'bold'}}>
            <p>Error</p>
            </div>    
        <CloseDiv onClick={onClose}>
            <MdClose size={24} color='#fff'/>
        </CloseDiv>
        </HeaderSection>
        <ModalContent>
        <ErrorText>{errorMessage}</ErrorText>
      </ModalContent>
    </ModalContainer>
  );
};

export default ErrorMessageModal;

// Styled components
const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  z-index: 1351;
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.danger};
  color: ${(props) => props.theme.colors.text};
  padding: 10px;
  width: 100%;
  //max-width: 500px;
  height: 100%;
  //border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.theme.colors.background2};
    height: 50px;
    padding: 10px;
`;

const CloseDiv = styled.div`
  background-color: red;
  border-radius: 10px;
  position: absolute;
  font-weight: 800;
  right: 0;
  margin-right: 10px;
  padding: 1px;
  cursor: pointer;
`;

const ErrorText = styled.p`
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 0;
`;
