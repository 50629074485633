import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { API_URL, baseURL } from '../config';
import styled from 'styled-components';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ShowStatusModel from './ShowStatusModel';

const BgBig = styled.div`
  background-color: ${(props) => props.theme.colors.background};
`;

const UserDetailsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }

  @media (max-width: 290px) {
    padding: 5px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
`;

const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 290px) {
    width: 80px;
    height: 80px;
  }
`;

const EditIcon = styled(MdEdit)`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 24px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 4px;
  display: ${(props) => (props.show ? 'block' : 'none')};

  @media (max-width: 290px) {
    font-size: 20px;
    top: 3px;
    right: 3px;
    padding: 3px;
  }
`;

const UserInfo = styled.ul`
  list-style-type: none;
  padding: 0;
  color: ${(props) => props.theme.colors.text};
  text-align: left;
  margin: 0 auto;
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const UserInfoItem = styled.li`
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.text};
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }

  @media (max-width: 290px) {
    font-size: 0.7rem;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 7px;
  }

  @media (max-width: 480px) {
    padding: 6px;
  }

  @media (max-width: 290px) {
    padding: 5px;
  }
`;

const WalletEarnings = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text};
  justify-content: space-around;
  margin: 20px 0;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
  }
`;

const TimeAgo = styled.h3`
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }

  @media (max-width: 290px) {
    margin-top: 5px;
  }
`;

const EditButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 20px 10px 0 0;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 18px;
    margin: 15px 8px 0 0;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 7px 16px;
    margin: 10px 6px 0 0;
  }

  @media (max-width: 290px) {
    font-size: 10px;
    padding: 6px 14px;
    margin: 5px 4px 0 0;
  }
`;

const CancelButton = styled(EditButton)`
  background-color: #f44336;

  &:hover {
    background-color: #d32f2f;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }

  @media (max-width: 290px) {
    margin-top: 5px;
  }
`;

const Hr = styled.hr`
  color: ${(props) => props.theme.colors.text};
  margin: 20px 0;

  @media (max-width: 768px) {
    margin: 15px 0;
  }

  @media (max-width: 480px) {
    margin: 10px 0;
  }

  @media (max-width: 290px) {
    margin: 5px 0;
  }
`;

const SubscriptionSection = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;

  h2 {
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.text};
  }

  label {
    display: block;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.text};
    font-weight: 500;
  }

  select, input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }

  @media (max-width: 290px) {
    padding: 5px;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 7px;
  }

  @media (max-width: 480px) {
    padding: 6px;
  }

  @media (max-width: 290px) {
    padding: 5px;
  }
`;

const SelectOption = styled.option`
  color: ${(props) => props.theme.colors.text};
`;

const ExistingPlansDiv = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;

  h3 {
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.text};
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }

  @media (max-width: 290px) {
    padding: 5px;
  }
`;

const ListCard = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  color: ${(props) => props.theme.colors.text};
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 290px) {
    padding: 8px;
  }

  ${EditButton} {
    margin-left: 10px;

    @media (max-width: 480px) {
      margin-left: 0;
      margin-top: 10px;
    }

    @media (max-width: 290px) {
      margin-top: 5px;
    }
  }
`;

const CommentsModalDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  //margin: 10px;
  background-color: ${props => props.theme.colors.background};
  //padding: 20px;
  border-top: 1px solid #ccc : ${props => props.theme.colors.text};
  border-radius: 6px;
`;

const UserDetails = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); 
  const [userDetails, setUserDetails] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedDetails, setEditedDetails] = useState({
    username: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    profile_picture: null,
  });
  const [plans, setPlans] = useState([]);
  const [planDetails, setPlanDetails] = useState({
    name: 'Plan A',
    price: '',
    duration: 'weekly',
  });
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState('');
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/User`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
          setUserDetails(response.data);
        } catch (error) {
          setError('Error fetching user details');
          console.error('Error fetching user details:', error.response?.data || error.message);
        }
      }
    };

    fetchUserDetails();
  }, [navigate]);

  useEffect(() => {
    if (userDetails) {
      const fetchPlans = async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
          try {
            const response = await axios.get(`${API_URL}/subscriptions/plans`, {
              headers: { Authorization: `Bearer ${token}` },
              withCredentials: true,
            });
            setPlans(response.data);
          } catch (error) {
            console.error('Error fetching subscription plans:', error);
          }
        }
      };

      fetchPlans();
    }
  }, [userDetails, navigate]);

  const formatTimeAgo = (createdDate) => {
    const currentDate = new Date();
    const timeDifference = currentDate - new Date(createdDate);
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);

    if (yearsDifference > 0) {
      return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
    } else if (monthsDifference > 0) {
      return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
    } else if (daysDifference > 0) {
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
    }
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
    setError('');
    if (userDetails && !editMode) {
      setEditedDetails({
        username: userDetails.username,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        profile_picture: null,
      });
      setPreviewImage(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEditedDetails((prevState) => ({
      ...prevState,
      profile_picture: file,
    }));
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleSaveChanges = async () => {
    const token = await getValidToken(navigate, true);
    if (token) {
      if (editedDetails.newPassword !== editedDetails.confirmPassword) {
        setError('New password and confirm password do not match.');
        setShowModal(true);
        return;
      }

      const formData = new FormData();
      formData.append('username', editedDetails.username);
      formData.append('oldPassword', editedDetails.oldPassword || '');
      formData.append('newPassword', editedDetails.newPassword || '');
      if (editedDetails.profile_picture) {
        formData.append('profile_picture', editedDetails.profile_picture);
      }

      try {
        await axios.put(`${API_URL}/User/update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setUserDetails((prevState) => ({
          ...prevState,
          username: editedDetails.username,
          profile_picture: editedDetails.profile_picture
            ? URL.createObjectURL(editedDetails.profile_picture)
            : prevState.profile_picture,
        }));
        setEditMode(false);
        setError('');
      } catch (error) {
        setError('Error updating user details');
        setShowModal(true);
        console.error('Error updating user details:', error.response?.data || error.message);
      }
    }
  };

  const handlePlanInputChange = (e) => {
    const { name, value } = e.target;
    setPlanDetails((prevState) => {
      let updatedDetails = { ...prevState, [name]: value };

      if (name === 'name') {
        switch (value) {
          case 'Plan A':
            updatedDetails.duration = 'weekly';
            break;
          case 'Plan B':
            updatedDetails.duration = 'monthly';
            break;
          case 'Plan C':
            updatedDetails.duration = 'yearly';
            break;
          default:
            updatedDetails.duration = '';
        }
      }

      return updatedDetails;
    });
  };

  const handleSavePlan = async (e) => {
    e.preventDefault();

    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        // Check for duplicate plan names
        const duplicatePlan = plans.find(
          (plan) => plan.name === planDetails.name && plan.id !== currentPlanId
        );
        if (duplicatePlan) {
          setError('A plan with this name already exists.');
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            setError('');
          }, 4000);
          return;
        }
        

        if (isEditingPlan) {
          await axios.put(`${API_URL}/subscriptions/${currentPlanId}`, planDetails, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
        } else {
          await axios.post(`${API_URL}/add/subscription/plan`, planDetails, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
        }
        const response = await axios.get(`${API_URL}/subscriptions/plans`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });
        setPlans(response.data);
        setPlanDetails({
          name: 'Plan A',
          price: '',
          duration: 'weekly',
        });
        setIsEditingPlan(false);
        setCurrentPlanId(null);
        handleClosePlanForm();
      } catch (error) {
        console.error('Error saving subscription plan:', error);
      }
    }
  };

  // const handleEditPlan = (plan, open) => {
  //   setPlanDetails(plan);
  //   setIsEditingPlan(true);
  //   setCurrentPlanId(plan.id);
  // };

  const handleEditPlan = (plan) => {
    setPlanDetails({
      name: plan.name,
      price: plan.price,
      duration: plan.duration,
    });
    setIsEditingPlan(true);
    setCurrentPlanId(plan.id);
  };

  const handleCancelEditPlan = () => {
    setPlanDetails({
      name: 'Plan A',
      price: '',
      duration: 'weekly',
    });
    setIsEditingPlan(false);
    setCurrentPlanId(null);
    handleClosePlanForm();
    setError('');
  };

  const handleClosePlanForm = () => {
    setShowSubscriptionForm(!showSubscriptionForm)
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
  };

  return (
    <BgBig>
    <UserDetailsContainer>
      {userDetails && (
        <>
          <ImageContainer>
            <Thumbnail src={previewImage || `${baseURL}${userDetails.profile_picture}`} alt="Profile" />
            <EditIcon
              show={editMode}
              onClick={() => document.getElementById('profile_picture_input').click()}
            />
            <Input
              type="file"
              id="profile_picture_input"
              name="profile_picture"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </ImageContainer>
          {[1, 3, 6, 7, 33].includes(userDetails.role_id) && <h3>Total Followers: 94k</h3>}
          <Hr />
          <h1>User Details</h1>
          <UserInfo>
            <UserInfoItem>Email: {userDetails.email}</UserInfoItem>
            <UserInfoItem>First Name: {userDetails.first_name}</UserInfoItem>
            <UserInfoItem>Last Name: {userDetails.last_name}</UserInfoItem>
            {editMode ? (
              <>
                <UserInfoItem>
                  Username: <Input type="text" name="username" value={editedDetails.username} onChange={handleInputChange} />
                </UserInfoItem>
                <UserInfoItem>
                  Old Password: <Input type="password" name="oldPassword" value={editedDetails.oldPassword} onChange={handleInputChange} />
                </UserInfoItem>
                <UserInfoItem>
                  New Password: <Input type="password" name="newPassword" value={editedDetails.newPassword} onChange={handleInputChange} />
                </UserInfoItem>
                <UserInfoItem>
                  Confirm Password: <Input type="password" name="confirmPassword" value={editedDetails.confirmPassword} onChange={handleInputChange} />
                </UserInfoItem>
              </>
            ) : (
              <UserInfoItem>Username: {userDetails.username}</UserInfoItem>
            )}
          </UserInfo>
          <WalletEarnings>
            <h2>Wallet: N{userDetails.wallet}</h2>
            {[1, 3, 6, 7, 33].includes(userDetails.role_id) && <h2>Earnings: N{userDetails.earnings}</h2>}
          </WalletEarnings>
          <Hr />
          <TimeAgo>Registered On: <time> {formatTimeAgo(userDetails.created_at)}</time> </TimeAgo>
          {plans && [1, 3, 6, 7, 33].includes(userDetails.role_id) && (
            <ExistingPlansDiv>
            <h3>Existing Plans</h3>
            <div>
              {plans.map((plan) => (
                <ListCard key={plan.id}>
                  {plan.name} - N{plan.price} - {plan.duration}
                  <EditButton onClick={() => handleEditPlan(plan, setShowSubscriptionForm(!showSubscriptionForm))}>Edit</EditButton>
                </ListCard>
              ))}
            </div>
          </ExistingPlansDiv>
          )}
          {showSubscriptionForm && (
                <SubscriptionSection>
                  <h2>Subscription Plans</h2>
                  <div>
                    <label>
                      Plan Name:
                      <Select name="name" value={planDetails.name} onChange={handlePlanInputChange} disabled={isEditingPlan}>
                        <SelectOption value="Plan A">Plan A</SelectOption>
                        <SelectOption value="Plan B">Plan B</SelectOption>
                        <SelectOption value="Plan C">Plan C</SelectOption>
                      </Select>
                    </label>
                    <label>
                      Price:
                      <Input type="text" name="price" value={planDetails.price} onChange={handlePlanInputChange} />
                    </label>
                    <label>
                      Duration:
                      <Select name="duration" value={planDetails.duration} onChange={handlePlanInputChange} disabled>
                        <SelectOption value="weekly">Weekly</SelectOption>
                        <SelectOption value="monthly">Monthly</SelectOption>
                        <SelectOption value="yearly">Yearly</SelectOption>
                      </Select>
                    </label>
                    <EditButton onClick={handleSavePlan}>{isEditingPlan ? 'Update Plan' : 'Add Plan'}</EditButton>
                    {isEditingPlan && <CancelButton onClick={handleCancelEditPlan}>Cancel</CancelButton>}
                  </div>
                </SubscriptionSection>
              )}
          {editMode ? (
            <>
              <EditButton onClick={handleSaveChanges}>Save</EditButton>
              <CancelButton onClick={handleEditToggle}>Cancel</CancelButton>
            </>
          ) : (
            <EditButton onClick={handleEditToggle}>Edit Profile</EditButton>
          )}
          {[1, 3, 6, 7, 33].includes(userDetails.role_id) && plans.length < 3 && (
            <>
              <EditButton onClick={() => setShowSubscriptionForm(!showSubscriptionForm)}>
                {showSubscriptionForm ? 'Cancel' : 'Set Subscription Plan'}
              </EditButton>
            </>
          )}
        </>
      )}
    {showModal && (
          <CommentsModalDiv>
          <ShowStatusModel message={error} onClose={handleCloseModal} />
          </CommentsModalDiv>
        )}
    
    </UserDetailsContainer>
    </BgBig>
  );
};

export default UserDetails;
