// import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import { v4 as uuidv4 } from 'uuid';

// const generateDeviceIdentifier = async () => {
//   const fp = await FingerprintJS.load();
//   const result = await fp.get();

//   console.log(result);

//   // Combine browser fingerprint with UUID
//   const deviceId = `${result.visitorId}-${uuidv4()}`;

//   // Store the unique identifier in local storage
//   localStorage.setItem('deviceIdentifier', deviceId);

//   return deviceId;
// };

// export default generateDeviceIdentifier;

// utils/deviceIdentifier.js
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const generateDeviceIdentifier = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

export default generateDeviceIdentifier;

