import styled, { keyframes } from 'styled-components';
import { FaHandPointDown, FaRegHandshake, FaRegThumbsUp } from 'react-icons/fa';

const Container = styled.div`
    width: 100%;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  //left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 10px; /* Add padding for some space around the content */
  z-index: 27;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
`;

// const ImageContainer = styled.div`
//     position: relative;
//     overflow: hidden;
//     height: 400px;
//     background-color: #000; //#f0f0f0; /* Background color for remaining space */
//     margin: auto;
// `;

// const Thumbnail = styled.img`
//     width: 100%;
//     height: 100%;
//     object-fit: contain; /* Fit the image within the container */
//     object-position: center; /* Center the image within the container */
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     cursor: pointer;
// `;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  //padding: 3px;
  overflow: hidden;
  background-color: black;
  border-radius: 7px;
  border: 2px solid ${props => props.theme.colors.text};

  @media (min-width: 768px) {
    height: 320px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Fit the image within the container */

  // @media (max-width: 768px) {
  //   max-width: 100%;
  // }
`;

const BrandLogo = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

// Keyframe animation for fade-in effect
const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Keyframe animation for shake effect
const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;

// Styled component for the handshake icon
const StyledHandshakeIcon = styled(FaRegHandshake)`
  opacity: 0;
  animation: ${fadeInAnimation} 0.6s ease-in forwards, ${shakeAnimation} 0.6s ease-in 1s infinite;
  font-size: 32px;
`;

const StyledFaRegThumbsUp = styled(FaRegThumbsUp)`
  opacity: 0;
  animation: ${fadeInAnimation} 0.6s ease-in forwards, ${shakeAnimation} 0.6s ease-in 1s infinite;
  font-size: 32px;
`;

// Kallo Anan and Sauke Anan Styles

const StyledHandIcon = styled(FaHandPointDown)`
  position: relative;
  top: 10px;  // Adjust this value to move the icon down
  transform: rotate(20deg);
`;

const Heading1 = styled.h1`
  font-size: 2rem;
  color: ${props => props.theme.colors.text};
  //text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const Heading2 = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.text};
  //text-align: center;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledButtonK = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: ${props => props.theme.colors.primary}; 
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 1rem;
  }
`;

const ContentContainer = styled.div`
  padding: 20px;
  font-size: 1rem;
  color: ${props => props.theme.colors.background};

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 0.875rem;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.75rem;
  }
`;

export { Container, Header, SpinnerContainer, ImageContainer, Thumbnail, BrandLogo, Span, StyledHandshakeIcon, StyledFaRegThumbsUp, StyledHandIcon, Heading1, Heading2, StyledLink, StyledButtonK, ContentContainer };