import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import styled from 'styled-components';
import Header from '../Header';
import Footer from '../Footer';
import Menu from '../Menu';
import TransactionReceipt from './TransactionsReceipt';
import PinConfirmModal from '../PinConfirmModal';
import ConfirmPhoneModal from '../ConfirmPhoneModal';
import ErrorMessageModal from '../ErrorMessageModal';
import Button from '../Button';
import { MdOutlineDoneOutline } from "react-icons/md";
import { IoMdWarning } from "react-icons/io";

const Airtime = () => {
  const [network, setNetwork] = useState('');
  const [airtimeType, setAirtimeType] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const [phone, setPhone] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [portedNumber, setPortedNumber] = useState(null);
  const [amount, setAmount] = useState('');
  const [discount, setDiscount] = useState('');

  const [isPinModalVisible, setIsPinModalVisible] = useState(false);
  const [isConfirmPhoneModalVisible, setIsConfirmPhoneModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [successfull, setSuccessfull] = useState(false);
  const [error, setError] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  // Handle submit with pin
  const handleSubmitWithPin = async (pin) => {
    const token = await getValidToken(navigate, true);
    if (!token || !pin) return;
    setIsPinModalVisible(false);

    setIsLoading(true);
    try {
      const data = {
        network,
        airtime_type: airtimeType,
        ported_number: portedNumber,
        amount,
        phone,
        pin,
      };

      const response = await axios.post(`${API_URL}/purchase-airtime`, data, {
        headers: {
          Authorization: `Bearer ${await getValidToken(navigate, false)}`,
        },
        withCredentials: true,
      });

      if (response.data.status === 'success') {
        setSuccessfull(true);
        setTransaction(response.data);
      } else {
        setError(response.data.msg || 'Transaction failed.');
      }
    } catch (error) {
      console.error('Error purchasing data:', error.response?.data || error.message);
      setError(error.response?.data?.msg || error.message || 'An error occurred.');
    } finally {
      setIsLoading(false);
      setIsPinModalVisible(false); // Hide pin modal after submission
    }
  };

  const calculateAmountToPayAndDiscount = (amount) => {
    if (!amount || isNaN(amount)) {
      setAmountToPay(0);
      setDiscount(0);
      return;
    }
  
    const discount = (1 / 100) * amount; // 1% discount
    const amountToPay = amount - discount; // Subtract 1% from amount
  
    setAmountToPay(amountToPay.toFixed(2)); // Round to 2 decimal places
    setDiscount(discount.toFixed(2)); // Set discount
  };
  

  const handleNetworkChange = (e) => {
    const selectedNetwork = e.target.value;
    setNetwork(selectedNetwork);
    setAirtimeType('');
    setAmountToPay(0);
  };

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setAirtimeType(selectedType);
    setAmountToPay(0);
  };

  const detectNetwork = (phone) => {
    if (phone.length < 4) return 'Unknown';

    const prefix = phone.slice(0, 4);
    const mtnPrefixes = ['0803', '0703', '0903', '0813', '0816', '0706', '0913', '0810'];
    const airtelPrefixes = ['0802', '0708', '0808', '0812', '0902', '0907', '0701', '0912'];
    const gloPrefixes = ['0805', '0905', '0705', '0815', '0915'];
    const mobile9Prefixes = ['0809', '0817', '0818', '0909', '0908'];

    if (mtnPrefixes.includes(prefix)) return 'MTN';
    if (airtelPrefixes.includes(prefix)) return 'AIRTEL';
    if (gloPrefixes.includes(prefix)) return 'GLO';
    if (mobile9Prefixes.includes(prefix)) return '9MOBILE';

    return 'Unknown';
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    setPhone(phoneNumber);

    const detectedNetwork = detectNetwork(phoneNumber);
    setNetworkName(detectedNetwork);
  };

  // Handle phone number confirmation
  const handleConfirmPhoneNumber = () => {
    setIsConfirmPhoneModalVisible(true);
  };

  // Handle user's response to phone confirmation
  const handlePhoneConfirmation = (confirm) => {
    setIsConfirmPhoneModalVisible(false); // Hide phone confirm modal
    if (confirm) {
      setIsPinModalVisible(true); // Show pin modal if confirmed
    }
  };

  const handleTransactionClose = () => {
    setTransaction(null);
    navigate('/');
  }

  const formatWallet = (wallet) => {
    // Check if wallet is a valid number or string that can be converted to a number
    if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
      return 'Invalid earnings';
    }

    // Convert earnings to number and ensure it's a valid number
    wallet = parseFloat(wallet);
    if (isNaN(wallet) || !isFinite(wallet)) {
      return 'Invalid wallet';
    }

    // Convert earnings to string with 2 decimal places
    let walletStr = wallet.toFixed(2);

    // Split wallet into whole and decimal parts
    const parts = walletStr.split('.');
    let wholePart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Add commas for thousands, millions, etc.
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    wholePart = wholePart.replace(regex, ',');

    // Return formatted earnings
    return wholePart + decimalPart;
  };

  return (
    <>
    <Header />
    <ParentPadding>
    <FormContainer>
      <TitleDiv>Buy Airtime</TitleDiv>
      <FormField>
        <Label>Network</Label>
        <Select value={network} onChange={handleNetworkChange} required>
          <option value="">Select Network</option>
          <option value="MTN">MTN</option>
          <option value="AIRTEL">Airtel</option>
          <option value="GLO">Glo</option>
          <option value="9MOBILE">9Mobile</option>
        </Select>
      </FormField>

      <FormField>
        <Label>Type</Label>
        <Select value={airtimeType} onChange={handleTypeChange} required>
          <option value="">Select Type</option>
          <option value="VTU">VTU</option>
          <option value="ShareAndSell">Share And Sell</option>
        </Select>
      </FormField>

      <FormField>
        <Label>Phone Number</Label>
        <Input
          type="tel"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="Enter Phone Number"
          required
        />
        {phone.length > 4 && networkName && (
          <DetectedNetwork>
            <p>
            Detected Network is: {networkName} 
            </p>
            {networkName === network ? <MdOutlineDoneOutline color='green'/> : <IoMdWarning color='red'/>}
          </DetectedNetwork>
        )}
      </FormField>

      <FormField>
        <Label>Amount</Label>
        <Input 
          type='tel'
          value={amount}
          onChange={(e) => {
            const newAmount = e.target.value;
            setAmount(newAmount);
            calculateAmountToPayAndDiscount(newAmount); 
          }}
          placeholder='Enter Amount'
          required
        />
      </FormField>

      <FormField>
        <Label>Amount To Pay</Label>
        <Input 
          type='text'
          value={`₦${formatWallet(amountToPay)}`}
          readOnly
        />
      </FormField>

      <FormField>
        <Label>Discount</Label>
        <Input 
          type='text'
          value={`₦${formatWallet(discount)}`}
          required
        />
      </FormField> 

      {networkName !== network && (
        <FormField>
        <Label>Ported Number</Label>
        <Input
          type="checkbox"
          checked={portedNumber}
          onChange={(e) => setPortedNumber(e.target.checked)}
        />
      </FormField>
      )}
  
      <FormField>
        <Button 
          onClick={handleConfirmPhoneNumber} 
          isLoading={isLoading} 
          disabled={isLoading} 
          errorMessage={error} 
          loginSuccess={successfull} 
          isP={true}
        >
          Purchase Airtime
        </Button>
      </FormField>

      {/* Show Pin Confirm Modal */}
      {isPinModalVisible && (
        <PinConfirmModal 
          onSubmit={handleSubmitWithPin} 
          onClose={() => setIsPinModalVisible(false)} 
        />
      )}

      {/* Show Confirm Phone Modal */}
      {isConfirmPhoneModalVisible && (
        <ConfirmPhoneModal 
          onConfirm={handlePhoneConfirmation} 
          onClose={() => setIsConfirmPhoneModalVisible(false)} 
          phone={phone} 
          network={network} 
        />
      )}

      {/* Show transaction receipt after a successful transaction */}
      {transaction && (
        <TransactionReceipt 
          transaction={transaction} 
          onClose={handleTransactionClose} 
        />
      )}
    </FormContainer>
    </ParentPadding>

    {showMenu && (
        <MenuSection>
          <Menu show={handleShowMenu} onClose={handleShowMenu} />
        </MenuSection>
      )}

    {error && (
      <ErrorMessageModal errorMessage={error} onClose={() => {setError('')}}/>
    )}  

    <div style={{height: '70px'}}/>
    <Footer show={handleShowMenu} />
    </>
  );
};

export default Airtime;

// Styled Components
const ParentPadding = styled.div`
    padding: 20px;
`;

const FormContainer = styled.div`
  padding: 20px;
  //background-color: #f8f9fa;
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 15px;
  color: ${(props) => props.theme.colors.text};
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 10px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
`;

const DetectedNetwork = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  gap: 5px;
  color: ${(props) => props.theme.colors.text};
`;

const MenuSection = styled.div``;
