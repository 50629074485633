// ForgotPasswordForm.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {API_URL} from '../config';
import { useNavigate } from 'react-router-dom';

import { Container, Logo, LoadingIcon, SuccessIcon, ErrorIcon, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton } from './Admin/styles'; // Import Card and FormControl directly
import { MdEmail } from 'react-icons/md';
import logoImg from '../assets/Arewamega.png';

const ForgotPasswordForm = () => {
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendSuccessfull, setSendSuccessfull] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate
  const [email, setEmail] = useState('');
  

  useEffect(() => {
    // Simulate page content rendering
    const timeout = setTimeout(() => {
      setScreenLoading(false); // Hide loading spinner after 2 seconds (adjust timing as needed)
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once after component mounts



  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
      setError(false);

    localStorage.setItem('resetEmail', email); // Store email in localStorage

    try {
      const response = await axios.post(`${API_URL}/users/forgot-password`, { email });

      if (response.status === 200) {
        console.log('Reset email sent successfully');
        setLoading(false);
        setSendSuccessfull(true);


      setTimeout(() => {
        setSendSuccessfull(false);
      //  setLoading(false);
        navigate('/verify-code');
    }, 2000); // Delay navigation for 2 seconds after showing success message

    } else {
      console.error('Error sending reset email:', response.data || 'Unknown error');
      setLoading(false);
    } 
  } catch (error) {
    console.error('Error sending reset email:', error.response?.data || error.message);
    setLoading(false);
    setError(error.message);
        setLoading(false);
          // Reset error message after 4 seconds
          setTimeout(() => {
            setError(null);
          }, 4000);
  }
  };


  return (
    <Container>
     {screenLoading ? (
                <LoadingIcon />
              ) : (   
      <Card>
      <Logo src={logoImg} alt="Logo" />
      <TitleContainer>
          <Title>Enter Your Registred Email</Title>
          
        </TitleContainer>
        {/* Display error or success message if login fails */}
        {sendSuccessfull && <p style={{ color: 'green', backgroundColor: 'black', padding: '5px' }}>Verification Code Sent successful!</p>}
        {error && <p style={{ color: 'black' }}>{error}</p>}

    <form onSubmit={handleSubmit}>
      {/* <label style={styles.label}>Email:</label> */}
      <InputBox>
            <Icon>
              <MdEmail style={{ color: 'black', fontSize: '24px' }} />
            </Icon>
        <StyledInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Label>EMAIL:</Label>
      </InputBox>

      <StyledButton type="submit" disabled={loading || sendSuccessfull}>
          {sendSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Send Reset Email'}
          {loading && <LoadingIcon />}
        </StyledButton>

      {/* <button type="submit" style={styles.button}>
        Send Reset Email
      </button> */}
    </form>
    </Card>
      )} 
    </Container>
  );
};

// const styles = {
//     form: {
//       maxWidth: '300px',
//       margin: '0 auto',
//     },
//     label: {
//       display: 'block',
//       marginBottom: '10px',
//     },
//     input: {
//       width: '100%',
//       padding: '8px',
//       marginBottom: '15px',
//     },
//     button: {
//       width: '100%',
//       padding: '10px',
//       backgroundColor: '#007bff',
//       color: '#fff',
//       border: 'none',
//       borderRadius: '5px',
//       cursor: 'pointer',
//     },
//   };

export default ForgotPasswordForm;
