import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import TermsAndConditionsForChannelRequest from './TermsAndConditionsForChannelRequest';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { FaBookOpenReader, FaSquareWhatsapp } from "react-icons/fa6";
import { LoadingIcon, SuccessIcon, ErrorIcon } from '../components/Admin/styles';

const FormContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
`;

const FormTitle = styled.h2`
    text-align: center;
`;

const FormField = styled.div`
    margin-bottom: 15px;
`;

const PtagDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px;
    gap: 10px;

    p {
        color: ${props => props.theme.colors.danger};
    }
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 8px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    &:disabled {
        background-color: green;
        cursor: not-allowed;
    }
`;

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background-color: green;
        cursor: not-allowed;
    }
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: ${props => (props.requestIsPending ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const TermsLink = styled.span`
    color: #007bff;
    cursor: pointer;
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

export {CheckboxContainer, Checkbox, TermsLink, FormModelDiv, PtagDiv, ModalBackdrop};

const BecomeCreatorForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [error, setError] = useState('');
    const [termsVisible, setTermsVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [userData, setUserData] = useState({
        username: '',
        phone: '',
        email: '',
    });

    const [formData, setFormData] = useState({
        youtube_channel: '',
        social_media_handle: '',
        reason: '',
        author_type: 'Teacher',
        main_ac_no: '',
        main_bank_name: '',
        main_ac_name: '',
    });

    const [requestStatus, setRequestStatus] = useState(null);
    const [requestMessage, setRequestMessage] = useState('');

    useEffect(() => {
        if (termsVisible) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
    
        // Cleanup on unmount
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [termsVisible]);
    

    useEffect(() => {
        const fetchUserData = async () => {
            const token = await getValidToken(navigate, true);
            if (token) {
                try {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        withCredentials: true,
                    };
                    const response = await axios.get(`${API_URL}/User`, config);
                    setUserData(response.data);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchRequestData = async () => {
            const token = await getValidToken(navigate, true);
            if (token) {
                try {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        withCredentials: true,
                    };
                    const response = await axios.get(`${API_URL}/author/request/get`, config);
                    setFormData({
                        youtube_channel: response.data.youtube_channel,
                        social_media_handle: response.data.social_media_handle,
                        reason: response.data.reason,
                        author_type: response.data.author_type,
                        main_ac_no: response.data.main_ac_no,
                        main_bank_name: response.data.main_bank_name,
                        main_ac_name: response.data.main_ac_name,
                    });
                    setRequestStatus(response.data.status);
                    setRequestMessage(response.data.message);
                } catch (error) {
                    if (error.response.status !== 404) {
                        console.error('Error fetching request data:', error);
                    }
                }
            }
        };

        fetchUserData();
        fetchRequestData();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginSuccess(false);
        setError('');

        if (!termsAccepted) {
            setError('You must accept the terms and conditions');
            setIsLoading(false);
            setTimeout(() => {
                setError('');
            }, 7000);
            return;
        }

        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                let response;
                if (requestStatus === 'rejected') {
                    response = await axios.put(`${API_URL}/author/request/update`, formData, config);
                } else {
                    response = await axios.post(`${API_URL}/author/request/post`, formData, config);
                }

                setRequestStatus('pending');
                setRequestMessage('Your request is under review');

                setError('');
                setLoginSuccess(true);
                setIsLoading(false);

                setTimeout(() => {
                    setLoginSuccess(false);
                }, 2000); 
            } catch (error) {
                console.error('Error submitting request:', error);
                setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            }
        }
    };

    const handleTermsToggle = () => {
        if (requestStatus === 'pending') {
            return;
        } else {
            setTermsVisible(!termsVisible);
        }
    };

    const handleIagreeChange = () => {
        setTermsAccepted(!termsAccepted);
    }

    return (
        <FormContainer>
            <FormTitle>Become a Creator <FaBookOpenReader /></FormTitle>
            {requestStatus === 'rejected' && <PtagDiv><p>{requestMessage}</p></PtagDiv>}
            {requestStatus === 'pending' && 
            <PtagDiv>
                <p>Your Request is Under Review</p>
                {requestStatus === 'pending' && <AudioIndicator requestIsPending={requestStatus}/>}
            </PtagDiv>}
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Label>Username</Label>
                    <Input type="text" value={userData.username} disabled />
                </FormField>
                <FormField>
                    <Label>Phone</Label>
                    <Input type="text" value={userData.phone} disabled />
                </FormField>
                <FormField>
                    <Label>Email</Label>
                    <Input type="email" value={userData.email} disabled />
                </FormField>
                <FormField>
                    <Label>Type of Author</Label>
                    <Select 
                        name="author_type" 
                        value={formData.author_type} 
                        onChange={handleChange}
                        disabled={requestStatus === 'pending'}
                    >
                        <option value="Teacher">Teacher</option>
                        <option value="Producer">Producer</option>
                        <option value="Artist">Artist</option>
                        <option value="Writer">Writer</option>
                    </Select>
                </FormField>
                <FormField>
                    <Label>YouTube Channel</Label>
                    <Input 
                        type="text" 
                        name="youtube_channel" 
                        value={formData.youtube_channel} 
                        onChange={handleChange} 
                        disabled={requestStatus === 'pending'}
                    />
                </FormField>
                <FormField>
                    <Label>Social Media Handle</Label>
                    <Input 
                        type="text" 
                        name="social_media_handle" 
                        value={formData.social_media_handle} 
                        onChange={handleChange} 
                        disabled={requestStatus === 'pending'}
                    />
                </FormField>
                <FormField>
                    <Label>Reason for Requesting</Label>
                    <TextArea 
                        name="reason" 
                        maxLength="100" 
                        value={formData.reason} 
                        onChange={handleChange} 
                        disabled={requestStatus === 'pending'}
                    />
                </FormField>
                <FormField>
                    <Label>Bank Name</Label>
                    <Input
                        name="main_bank_name" 
                        value={formData.main_bank_name} 
                        onChange={handleChange} 
                        disabled={requestStatus === 'pending'}
                    />
                </FormField>
                <FormField>
                    <Label>Account Number</Label>
                    <Input 
                        name="main_ac_no" 
                        value={formData.main_ac_no} 
                        onChange={handleChange} 
                        disabled={requestStatus === 'pending'}
                    />
                </FormField>

                <FormField>
                    <Label>Account Name</Label>
                    <Input
                        name="main_ac_name" 
                        value={formData.main_ac_name} 
                        onChange={handleChange} 
                        disabled={requestStatus === 'pending'}
                    />
                </FormField>

                {error && <PtagDiv><p>{error}</p></PtagDiv>}

                <CheckboxContainer>
                    <Checkbox 
                        type="checkbox" 
                        checked={termsAccepted || requestStatus === 'pending'} 
                        onChange={handleIagreeChange}
                        disabled={requestStatus === 'pending'} 
                    />
                    <TermsLink onClick={handleTermsToggle}>Terms and Conditions</TermsLink>
                </CheckboxContainer>

                {termsVisible && (
                    <>
                    <ModalBackdrop onClick={handleTermsToggle} />
                    <FormModelDiv>
                    <TermsAndConditionsForChannelRequest onClose={handleTermsToggle} iAgree={handleIagreeChange} />
                    </FormModelDiv>
                    </>
                )}
        
                <Button onClick={handleSubmit} disabled={requestStatus === 'pending' || isLoading || loginSuccess}>
                {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Submit Request'}
                {isLoading && <LoadingIcon />}
                </Button>
            </form>
        </FormContainer>
    );
};

export default BecomeCreatorForm;
