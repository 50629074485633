import { 
  UPDATE_EMAIL, 
  REGISTER_SUCCESS, 
  LOGOUT, 
  LOGIN_SUCCESS,
  VERIFY_CODE_REQUEST,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  SET_RESET_EMAIL,
  SET_USER_ID
 } from './actionTypes'; // Add LOGIN_SUCCESS import

import axios from 'axios';
import { API_URL } from '../config';
//export const setUserActive = (active) => ({
//  type: SET_USER_ACTIVE,
//  payload: active,
//});

// In your Redux actions file (e.g., authActions.js)
export const registerSuccess = (email) => {
  return {
    type: REGISTER_SUCCESS,
    payload: email,
  };
};


export const updateEmail = (newEmail) => {
  return {
    type: UPDATE_EMAIL, // Define the action type
    payload: newEmail, // Pass the new email as the payload
  };
};

export const logout = () => {
  // Clear localStorage items related to authentication
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userId');

  return { type: LOGOUT };
};

export const loginSuccess = (token, userId) => {
  localStorage.setItem('jwtToken', token);
  return {
    type: LOGIN_SUCCESS,
    payload: { token, userId }, // Ensure userId is included in the payload
  };
};


export const forgotPassword = (email) => async (dispatch) => {
  try {
    await axios.post(`${API_URL}/users/forgot-password`, { email });
    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
  } catch (err) {
    console.error('Error sending reset password email:', err.response?.data || err.message);
    // Handle error response from server
  }
};

export const updatePassword = ({ email, verificationCode, newPassword }) => async (dispatch) => {
  try {
     await axios.put(`${API_URL}/users/update-password`, { email, verificationCode, newPassword });
    //console.log(res.data); // Handle response as needed
    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
  } catch (err) {
    console.error('Error updating password:', err.response?.data || err.message);
    // Handle error response from server
  }
};

export const verifyCode = ({ email, verificationCode }) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_CODE_REQUEST });

    // Send a request to the backend to verify the code
    const res = await axios.post(`${API_URL}/users/verify-code`, { email, verificationCode });

    dispatch({ type: VERIFY_CODE_SUCCESS, payload: res.data }); // Update state on successful verification
  } catch (err) {
    dispatch({ type: VERIFY_CODE_FAILURE, payload: err.response?.data || err.message }); // Update state on verification failure
  }
};

export const setResetEmail = (email) => ({
  type: SET_RESET_EMAIL,
  payload: email,
});

// Action to dispatch user ID upon successful login
export const setUserId = (userId) => ({
  type: SET_USER_ID,
  payload: userId,
});

// Action to fetch user details from database
//export const fetchUserDetails = (userId) => async (dispatch) => {
  //try {
    ///const response = await axios.get(`${API_URL}/users/${userId}`);
    //dispatch({ type: FETCH_USER_DETAILS_SUCCESS, payload: response.data });
  //} catch (error) {
    //console.error('Error fetching user details:', error.response?.data || error.message);
    //dispatch({ type: FETCH_USER_DETAILS_FAILURE });
  //}
//};

// Action to fetch user details from database based on user ID
export const fetchUserDetails = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/users/${userId}`);
    const userDetails = response.data; // Assuming the API returns user details including username
    dispatch({ type: FETCH_USER_DETAILS_SUCCESS, payload: userDetails });
  } catch (error) {
    console.error('Error fetching user details:', error.response?.data || error.message);
    dispatch({ type: FETCH_USER_DETAILS_FAILURE });
  }
};

