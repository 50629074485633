import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from './auth'; 

const AuthCheck = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      // Assume admin token check is similar but may require different handling
      const token = await getValidToken(navigate, false);
      console.log('Checking admin authentication...', token);
      if (!token) {
        console.log('No valid token found, navigating to admin login...');
        navigate('/admin');
      } else {
        console.log('Valid token found for admin:', token);
      }
    };

    checkAuth();
  }, [navigate]);

  return children;
};

export default AuthCheck;
