import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';

const Container = styled.div`
  min-height: 100vh;

`;

const MenuSection = styled.div`

`;

const ProfileContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ProfileTitle = styled.h1`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s;
  
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const PinInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
`;

const EditButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const EditOptionsContainer = styled.div`
  margin-top: 20px;
`;

const UpdateButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }
`;

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [showMenu, setShowMenu] =  useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [pin, setPin] = useState('');
  const [oldPin, setOldPin] = useState('');
  const [showPin, setShowPin] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isEditingPin, setIsEditingPin] = useState(false);
  const navigate = useNavigate();

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  }

  // Fetch user data from the backend
  useEffect(() => {
    const fetchUserData = async () => {
        const token = await getValidToken(navigate, true)
        if (token) {
      try {
        const response = await axios.get(`${API_URL}/user/profile/getAll`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
    };

    fetchUserData();
  }, [navigate]);

  // Handle password update
  const handlePasswordUpdate = async () => {
    const token = await getValidToken(navigate, true)
        if (token) {
    try {
      const response = await axios.post(`${API_URL}/user/profile/update-password`, {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        oldPassword,
        newPassword,
      });
      alert(response.data.message || 'Password updated successfully');
    } catch (error) {
      console.error('Error updating password:', error.response?.data || error.message);
      alert(error.response?.data?.message || 'Failed to update password');
    }
}
  };

  // Handle PIN update
  const handlePinUpdate = async () => {
    const token = await getValidToken(navigate, true)
        if (token) {
    try {
      const response = await axios.post(`${API_URL}/user/profile/update-pin`, {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        oldPin,
        newPin: pin,
      });
      alert(response.data.message || 'PIN updated successfully');
    } catch (error) {
      console.error('Error updating PIN:', error.response?.data || error.message);
      alert(error.response?.data?.message || 'Failed to update PIN');
    }
}
  };

  return (
    <Container>
    <Header />
    <div style={{height: '10px'}}/>
    <ProfileContainer>
      <ProfileTitle>Profile</ProfileTitle>

      <FormGroup>
        <Label>First Name</Label>
        <Input type="text" value={userData.first_name} readOnly />
      </FormGroup>

      <FormGroup>
        <Label>Last Name</Label>
        <Input type="text" value={userData.last_name} readOnly />
      </FormGroup>

      <FormGroup>
        <Label>Email</Label>
        <Input type="email" value={userData.email} readOnly />
      </FormGroup>

      <FormGroup>
        <Label>Phone</Label>
        <Input type="tel" value={userData.phone} readOnly />
      </FormGroup>

      <FormGroup>
        <Label>PIN</Label>
        <PinInputContainer>
          <Input
            type={showPin ? 'text' : 'password'}
            value={userData.pin ? userData.pin : '****'}
            readOnly
          />
          <ToggleButton onClick={() => setShowPin(!showPin)}>
            {showPin ? <MdVisibilityOff /> : <MdVisibility />}
          </ToggleButton>
        </PinInputContainer>
      </FormGroup>

      <EditButton onClick={() => setShowEditOptions(!showEditOptions)}>
        {showEditOptions ? 'Cancel Edit' : 'Edit Profile'}
      </EditButton>

      {showEditOptions && (
        <EditOptionsContainer>
          {/* Update Password Section */}
          <UpdateButton onClick={() => setIsEditingPassword(!isEditingPassword)}>
            {isEditingPassword ? 'Cancel Password Update' : 'Update Password'}
          </UpdateButton>

          {isEditingPassword && (
            <FormGroup>
              <Label>Old Password</Label>
              <Input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <Label>New Password</Label>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <UpdateButton onClick={handlePasswordUpdate}>Submit Password</UpdateButton>
            </FormGroup>
          )}

          {/* Update PIN Section */}
          <UpdateButton onClick={() => setIsEditingPin(!isEditingPin)}>
            {isEditingPin ? 'Cancel PIN Update' : 'Update PIN'}
          </UpdateButton>

          {isEditingPin && (
            <FormGroup>
              <Label>Old PIN</Label>
              <Input
                type="password"
                value={oldPin}
                onChange={(e) => setOldPin(e.target.value)}
              />
              <Label>New PIN</Label>
              <Input
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
              <UpdateButton onClick={handlePinUpdate}>Submit PIN</UpdateButton>
            </FormGroup>
          )}
        </EditOptionsContainer>
      )}
    </ProfileContainer>
    {showMenu && (
        <MenuSection>
          <Menu show={handleShowMenu} onClose={handleShowMenu}/>
        </MenuSection>
      )}
      <div style={{height: '70px'}}/>
      <Footer show={handleShowMenu} />
    </Container>
  );
};

export default Profile;
