import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../config';
import BookReader from './BookReader';
import SpinnerArewaLogo from './SpinnerArewaLogo';
import { LuServerCog } from "react-icons/lu";

const LissafinRayuwa = () => {
    const [dob, setDob] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [lifePathNumber, setLifePathNumber] = useState(null);
    const [expressionNumber, setExpressionNumber] = useState(null);
    const [soulUrgeNumber, setSoulUrgeNumber] = useState(null);
    const [personalityNumber, setPersonalityNumber] = useState(null);
    const [maturityNumber, setMaturityNumber] = useState(null);
    const [karmaNumber, setKarmaNumber] = useState(null);
    const [birthdayNumber, setBirthdayNumber] = useState(null);
    const [hiddenPassionNumbers, setHiddenPassionNumbers] = useState([]);
    const [personalYearNumber, setPersonalYearNumber] = useState(null);
    const [challengesNumber, setChallengesNumber] = useState(null);
    const [numerologicalCycles, setNumerologicalCycles] = useState([]);
    const [wealthPathNumber, setWealthPathNumber] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(null);
  
    const pythagoreanTable = {
      A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9,
      J: 1, K: 2, L: 3, M: 4, N: 5, O: 6, P: 7, Q: 8, R: 9,
      S: 1, T: 2, U: 3, V: 4, W: 5, X: 6, Y: 7, Z: 8
    };
  
    const vowels = ['A', 'E', 'I', 'O', 'U', 'Y'];
    const consonants = Object.keys(pythagoreanTable).filter(letter => !vowels.includes(letter));
  
    const reduceToSingleDigit = (number) => {
      while (number > 9 && ![11, 22, 33].includes(number)) {
        number = number.toString().split('').map(Number).reduce((acc, num) => acc + num, 0);
      }
      return number;
    };
  
    const calculateLifePathNumber = (dateOfBirth) => {
      const digits = dateOfBirth.replace(/[^0-9]/g, '').split('').map(Number);
      const sum = digits.reduce((acc, num) => acc + num, 0);
      return reduceToSingleDigit(sum);
    };
  
    const calculateExpressionNumber = (fullName) => {
      const nameWithoutSpaces = fullName.replace(/\s+/g, '').toUpperCase();
      const nameNumbers = nameWithoutSpaces.split('').map(letter => pythagoreanTable[letter]);
      const sum = nameNumbers.reduce((acc, num) => acc + num, 0);
      return reduceToSingleDigit(sum);
    };
  
    const calculateSoulUrgeNumber = (fullName) => {
      const nameWithoutSpaces = fullName.replace(/\s+/g, '').toUpperCase();
      const vowelNumbers = nameWithoutSpaces.split('').filter(letter => vowels.includes(letter)).map(vowel => pythagoreanTable[vowel]);
      const sum = vowelNumbers.reduce((acc, num) => acc + num, 0);
      return reduceToSingleDigit(sum);
    };
  
    const calculatePersonalityNumber = (fullName) => {
      const nameWithoutSpaces = fullName.replace(/\s+/g, '').toUpperCase();
      const consonantNumbers = nameWithoutSpaces.split('').filter(letter => consonants.includes(letter)).map(consonant => pythagoreanTable[consonant]);
      const sum = consonantNumbers.reduce((acc, num) => acc + num, 0);
      return reduceToSingleDigit(sum);
    };
  
    const calculateMaturityNumber = (lifePathNumber, expressionNumber) => {
      return reduceToSingleDigit(lifePathNumber + expressionNumber);
    };
  
    const calculateKarmaNumber = (fullName) => {
      const nameWithoutSpaces = fullName.replace(/\s+/g, '').toUpperCase();
      const allNumbers = nameWithoutSpaces.split('').map(letter => pythagoreanTable[letter]);
      const sum = allNumbers.reduce((acc, num) => acc + num, 0);
      return reduceToSingleDigit(sum);
    };
  
    const calculateBirthdayNumber = (dateOfBirth) => {
      return reduceToSingleDigit(parseInt(dateOfBirth.split('-')[2], 10));
    };
  
    const calculateHiddenPassionNumbers = (fullName) => {
      const nameWithoutSpaces = fullName.replace(/\s+/g, '').toUpperCase();
      const letterNumbers = nameWithoutSpaces.split('').map(letter => pythagoreanTable[letter]);
      const counts = letterNumbers.reduce((acc, num) => {
        acc[num] = (acc[num] || 0) + 1;
        return acc;
      }, {});
      return Object.keys(counts).map(Number).filter(num => counts[num] > 1);
    };
  
    const calculatePersonalYearNumber = (dob, currentYear) => {
      const birthMonthAndDay = dob.split('-').slice(1).join('');
      const yearSum = currentYear.toString().split('').map(Number).reduce((acc, num) => acc + num, 0);
      const personalYear = reduceToSingleDigit(parseInt(birthMonthAndDay, 10) + yearSum);
      return personalYear;
    };
  
    const calculateChallengesNumber = (lifePathNumber, expressionNumber) => {
      return reduceToSingleDigit(lifePathNumber + expressionNumber);
    };
  
    const calculateNumerologicalCycles = (lifePathNumber) => {
      const cycles = [];
      const startYear = 2024; // Example start year
      for (let i = 0; i < 3; i++) {
        const cycleYear = startYear + i * 9;
        cycles.push(reduceToSingleDigit(cycleYear % 9 + lifePathNumber));
      }
      return cycles;
    };
  
    const calculateWealthPathNumber = (dateOfBirth) => {
        // Ensure dateOfBirth is in a valid format
        // This function expects the format 'YYYY-MM-DD'
        const dateParts = dateOfBirth.split('-');
        if (dateParts.length !== 3) {
          console.error('Invalid date format. Expected YYYY-MM-DD');
          return 'NaN';
        }
      
        // Extract day, month, and year
        const [year, month, day] = dateParts;
      
        // Combine day, month, and year into a single string
        const combinedString = day + month + year;
      
        // Convert combinedString to a number and sum the digits
        let combinedSum = combinedString.split('').map(Number).reduce((acc, num) => {
          return !isNaN(num) ? acc + num : acc;
        }, 0);
      
        // Function to reduce to a single digit while respecting Master Numbers
        const reduceToSingleDigitOrMasterNumber = (num) => {
          // Check if it's a Master Number
          if (num === 11 || num === 22) {
            return num; // Return Master Number as is
          }
          // Reduce to single digit
          while (num > 9) {
            num = num.toString().split('').map(Number).reduce((acc, digit) => acc + digit, 0);
          }
          return num;
        };
      
        // Reduce the combinedSum
        let wealthPathNumber = reduceToSingleDigitOrMasterNumber(combinedSum);
      
        // Special handling if combinedSum is a Master Number
        if (wealthPathNumber === 11 || wealthPathNumber === 22) {
          // Recalculate by summing original digits again
          let originalSum = combinedString.split('').map(Number).reduce((acc, num) => acc + num, 0);
          wealthPathNumber = reduceToSingleDigitOrMasterNumber(originalSum);
        }
      
        return wealthPathNumber;
      };

      console.log(dob);

      console.log(wealthPathNumber);
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      setIsLoading(true);
  
      const fullName = `${firstName} ${middleName} ${lastName}`.trim();
      const lifePathNum = calculateLifePathNumber(dob);
      const expressionNum = calculateExpressionNumber(fullName);
      const soulUrgeNum = calculateSoulUrgeNumber(fullName);
      const personalityNum = calculatePersonalityNumber(fullName);
      const maturityNum = calculateMaturityNumber(lifePathNum, expressionNum);
      const karmaNum = calculateKarmaNumber(fullName);
      const birthdayNum = calculateBirthdayNumber(dob);
      const hiddenPassionNums = calculateHiddenPassionNumbers(fullName);
      const personalYearNum = calculatePersonalYearNumber(dob, new Date().getFullYear());
      const challengesNum = calculateChallengesNumber(lifePathNum, expressionNum);
      const numerologicalCycles = calculateNumerologicalCycles(lifePathNum);
      const wealthPathNum = calculateWealthPathNumber(dob);
  
      setLifePathNumber(lifePathNum);
      setExpressionNumber(expressionNum);
      setSoulUrgeNumber(soulUrgeNum);
      setPersonalityNumber(personalityNum);
      setMaturityNumber(maturityNum);
      setKarmaNumber(karmaNum);
      setBirthdayNumber(birthdayNum);
      setHiddenPassionNumbers(hiddenPassionNums);
      setPersonalYearNumber(personalYearNum);
      setChallengesNumber(challengesNum);
      setNumerologicalCycles(numerologicalCycles);
      setWealthPathNumber(wealthPathNum);
  
      try {
        const response = await axios.get(`${API_URL}/public/numerology/results`, {
          params: {
            lifePathNumber: lifePathNum,
            expressionNumber: expressionNum,
            soulUrgeNumber: soulUrgeNum,
            personalityNumber: personalityNum,
            maturityNumber: maturityNum,
            karmaNumber: karmaNum,
            birthdayNumber: birthdayNum,
            hiddenPassionNumbers: hiddenPassionNums,
            personalYearNumber: personalYearNum,
            challengesNumber: challengesNum,
            numerologicalCycles: numerologicalCycles,
            wealthPathNumber: wealthPathNum
          }
        });
  
        setResults(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
        return (
            <SpinnerArewaLogoDiv>
            <SpinnerArewaLogo />
            </SpinnerArewaLogoDiv>
        )
    }
  
    return (
        <FormContainer>
          <TitleDiv><h2>Lissafin Rayuwa</h2> <LuServerCog size={30} color='#fff' /></TitleDiv>
          {!results ? (
            <form onSubmit={handleSubmit}>
            <Section>
                <FormField>
                    <Label>Optional: Enter Your Date of Birth</Label>
                    <Input
                        type="date"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        placeholder="YYYY-MM-DD"
                    />
                </FormField>
            </Section>
      
            <Section>
            <FormField>
                    <Label>First Name</Label>
                        <Input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            required
                        />
            </FormField>

            <FormField>
                    <Label>Last Name</Label>
                        <Input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            required
                        />
            </FormField>

            <FormField>
                    <Label>Middle Name</Label>
                        <Input
                            type="text"
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                            placeholder="Middle Name"
                        />
            </FormField>

            </Section>
      
            <Section>
              <SubmitButton type="submit">Calculate and Fetch Results</SubmitButton>
            </Section>
          </form>
          ) : (
            <ResetResultsDiv><Button onClick={() => {setResults(null)}}>Change Details</Button></ResetResultsDiv>
          )}
          
          {results && (
            <ResultsSection>
              <h3>Sakamakon Bayanan Ka</h3>
      
              {results.lifePathText && (
                <BookDiv>
                  <BookReader content={results.lifePathText} title={`Life Path - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.expressionText && (
                <BookDiv>
                  <BookReader content={results.expressionText} title={`Expression Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.soulUrgeText && (
                <BookDiv>
                  <BookReader content={results.soulUrgeText} title={`Soul Urge - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.personalityText && (
                <BookDiv>
                  <BookReader content={results.personalityText} title={`Personality Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.maturityText && (
                <BookDiv>
                  <BookReader content={results.maturityText} title={`Maturity Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.karmaText && (
                <BookDiv>
                  <BookReader content={results.karmaText} title={`Karma Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.birthdayText && (
                <BookDiv>
                  <BookReader content={results.birthdayText} title={`Birthday Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.hiddenPassionTexts?.length > 0 && (
                <BookDiv>
                  {results.hiddenPassionTexts.map((text, index) => (
                    <BookReader
                      key={index}
                      content={text}
                      title={`Hidden Passion ${index + 1} - ${firstName} ${lastName} ${middleName}`}
                    />
                  ))}
                </BookDiv>
              )}
      
              {results.personalYearText && (
                <BookDiv>
                  <BookReader content={results.personalYearText} title={`Personal Year Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.challengesText && (
                <BookDiv>
                  <BookReader content={results.challengesText} title={`Challenges Number - ${firstName} ${lastName} ${middleName}`} />
                </BookDiv>
              )}
      
              {results.numerologicalCyclesTexts?.length > 0 && (
                <BookDiv>
                  {results.numerologicalCyclesTexts.map((text, index) => (
                    <BookReader
                      key={index}
                      content={text}
                      title={`Numerological Cycle ${index + 1} - ${firstName} ${lastName} ${middleName}`}
                    />
                  ))}
                </BookDiv>
              )}
      
              {results.wealthPathTexts?.length > 0 && (
                <BookDiv>
                  {results.wealthPathTexts.map((text, index) => (
                    <BookReader
                      key={index}
                      content={text}
                      title={`Wealth Path ${index + 1} - ${firstName} ${lastName} ${middleName}`}
                    />
                  ))}
                </BookDiv>
              )}
            </ResultsSection>
          )}
        </FormContainer>
      );      
  };
  
  export default LissafinRayuwa;
  

// Styled Components
const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  color: ${(props) => props.theme.colors.text};
  gap: 10px;
`;

const ResetResultsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const Button = styled.button`
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.danger};
  color: ${(props) => props.theme.colors.text};
  padding: 10px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: ${props => props.theme.colors.background}; 
  color: ${props => props.theme.colors.text};
`;

const BookDiv = styled.div`
    margin: 20px 0px;
`;

const SpinnerArewaLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transaparent;
  min-height: 100vh;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const ResultsSection = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
`;
