import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../reducers/actions';

const Logout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action to clear authentication state in Redux
    dispatch(logout());

    // Remove the JWT token cookie
    document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Redirect to the login page or any other appropriate page
    window.location.replace('/login'); // Use window.location.replace for full page reload
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default Logout;
