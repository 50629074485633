import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { API_URL } from '../config';
import { FaEye, FaEyeSlash, FaPlus } from "react-icons/fa";

const WelcomeAndBalanceContainer = styled.div`
    width: 100%;
    height: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    aling-items: center;
    background-color: ${(props) => props.theme.colors.background2};
    color: ${(props) => props.theme.colors.text};
    margin: 15px 0px;
`;

const WelcomeUserDiv = styled.div`

`;

const WalletBalanceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.background};
    border-radius: 15px;
    border: 1px solid ${(props) => props.theme.colors.text};
    padding: 10px;
    gap: 10px;
    margin-top: 50px;
`;

const BalanceDiv = styled.div`
    display: flex;
    gap: 10px;
`;

const AddfundIconDiv = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    padding: 5px;
`;

const WelcomeAndBalance = ({showAddFund}) => {
    //const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState('');
    const [showBalance, setShowBalance] = useState(false);
    const navigate = useNavigate();

    const handleShowBalance = () => {
        setShowBalance(!showBalance);
    }

    useEffect(() => {
        const fetchData = async () => {
          const token = await getValidToken(navigate, true); // Use await here
          if (token) {
            try {
              const response = await axios.get(`${API_URL}/User/wallet/details`, {
                headers: {
                  Authorization: `Bearer ${token}`
                },
                withCredentials: true
              });
              setUserData(response.data);
            } catch (error) {
              console.error('Error fetching user data:', error.response?.data || error.message);
            }
          } 
        };
    
        fetchData();
      }, [navigate]);

  // Format wallet balance with commas
  const formatWallet = (wallet) => {
    // Check if wallet is a valid number or string that can be converted to a number
    if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
      return '******'; //'Invalid earnings';
    }

    // Convert earnings to number and ensure it's a valid number
    wallet = parseFloat(wallet);
    if (isNaN(wallet) || !isFinite(wallet)) {
      return 'Invalid wallet';
    }

    // Convert earnings to string with 2 decimal places
    let walletStr = wallet.toFixed(2);

    // Split wallet into whole and decimal parts
    const parts = walletStr.split('.');
    let wholePart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Add commas for thousands, millions, etc.
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    wholePart = wholePart.replace(regex, ',');

    // Return formatted earnings
    return wholePart + decimalPart;
  };

  // Format earnings with custom function
  const formattedWalletBalance = formatWallet(userData?.wallet);

  return (
    <WelcomeAndBalanceContainer>
        <WelcomeUserDiv>
        <h3>Barka Da Zuwa! {userData.first_name}</h3>
        </WelcomeUserDiv>
        <WalletBalanceDiv>
            <BalanceDiv><h3>Balance: {showBalance ? `₦${formattedWalletBalance}` : '******'} </h3> {showBalance ? <FaEyeSlash onClick={handleShowBalance} /> : <FaEye onClick={handleShowBalance} />}</BalanceDiv>
            <AddfundIconDiv onClick={showAddFund}><FaPlus color='white' size={20}/></AddfundIconDiv>
        </WalletBalanceDiv>
    </WelcomeAndBalanceContainer>
  );
}

export default WelcomeAndBalance;
