import axios from 'axios';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';

const logoutUser = (navigate) => {
  Cookies.remove('jwtToken', { path: '/' });
  Cookies.remove('refreshToken', { path: '/' });
  navigate('/login');
};


const isTokenExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return exp < Date.now() / 1000;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Token is invalid or expired
  }
};

export const getValidToken = async (navigate, requireLogin = true) => {
  let token = Cookies.get('jwtToken');
  const refreshToken = Cookies.get('refreshToken');


  if (!token || !refreshToken) {
    if (requireLogin) {
      logoutUser(navigate);
    }
    return null;
  }

  if (isTokenExpired(token)) {

    if (isTokenExpired(refreshToken)) {
      if (requireLogin) {
        logoutUser(navigate);
      }
      return null;
    }

    try {
      const response = await axios.post(`${API_URL}/auth/refresh-token`,  {token: refreshToken}, { withCredentials: true });
      token = response.data.token;
      Cookies.set('jwtToken', token, { secure: true, sameSite: 'Strict', path: '/' });
    } catch (error) {
      console.error('Error refreshing token:', error);
      logoutUser(navigate);
      return null;
    }
  }

  // Final check to ensure the token is valid
  if (isTokenExpired(token)) {
    if (requireLogin) {
      logoutUser(navigate);
    }
    return null;
  }

  return token;
};

// Function to get user ID from token stored in cookies
export const getUserIdFromToken = async (navigate, requireLogin = true) => {
  const token = await getValidToken(navigate, requireLogin);
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userId; // Adjust based on your token's structure
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }
  return null;
};
