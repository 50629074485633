import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios'; 
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate, Link } from 'react-router-dom';
import { FaSquareWhatsapp } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { LoadingIcon, SuccessIcon, ErrorIcon } from '../components/Admin/styles';

const ContactUs = () => {
  const [supportInfo, setSupportInfo] = useState({ email: '', whatsapp_number: '' });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSupportInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/public/support/get/info`);
        setSupportInfo(response.data);
      } catch (error) {
        setError('Error fetching support information');
      }
    };
    fetchSupportInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoginSuccess(false);
    setError('');

    if (!message) {
      setError('Type message to send');
      setIsLoading(false);
      setTimeout(() => {
          setError('');
      }, 3000);
      return;
  }
    const token = await getValidToken(navigate, true);
        if (token) {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        };
      await axios.post(`${API_URL}/support/submit`, { message }, config);

      setMessage('');
      setError('');
      setLoginSuccess(true);
      setIsLoading(false);

      setTimeout(() => {
        setLoginSuccess(false);
        }, 2000); 
    } catch (error) {
      console.error('Error sending message:', error);
      setError(error.msg || 'Error sending message');
      setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
    }
}
  };

  return (
    <Container>
    <Title><h1>Contact Us</h1></Title>
      <SectionsDiv>
      <Pdiv>
      <p>If you have any questions or need help, please contact us:</p>
      </Pdiv>
      <EmailDiv>
      <MdEmail size={34} color='#759ec7' />
      <Button style={{backgroundColor: '#759ec7', color: 'black'}}>
        <StyledLink to={`mailto:${supportInfo.email}`} style={{color: 'black'}}>
        {supportInfo.email}
        </StyledLink>
      </Button>
      </EmailDiv>
      <WhatsAppDiv>
      <FaSquareWhatsapp size={34} color='green' />
      <Button>
        <StyledLink to={`https://wa.me/${supportInfo.whatsapp_number}`}>
        Click Here To Chats On WhatsApp
        </StyledLink>
      </Button>
      </WhatsAppDiv>
      </SectionsDiv>
      
      <Form onSubmit={handleSubmit}>
        <label htmlFor="message">Your Message</label>
        <textarea
          id="message"
          value={message}
          maxLength="800"
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        </Form>
        {error && (
        <ErrorDiv>
          <Error>{error}</Error>
        </ErrorDiv>)}
        <Button onClick={handleSubmit} disabled={isLoading || loginSuccess}>  
          {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Send Message'}
          {isLoading && <LoadingIcon />}
        </Button>
    </Container>
  );
};

export default ContactUs;

const Container = styled.div`
  max-width: 600px;
  min-height: 100vh;
  background: ${props => props.theme.colors.background};
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.text};
`;

const Pdiv = styled.div`
  color: ${props => props.theme.colors.text};
`;

const SectionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
`;

const EmailDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

const WhatsAppDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

const ErrorDiv = styled.div`
  margin: 10px;
`;

const Error = styled.p`
  color: red;
`;

const Form = styled.form`
  display: flex;
  color: ${props => props.theme.colors.text};
  flex-direction: column;

  label {
    margin: 10px 0 5px;
  }

  textarea {
    padding: 10px;
    margin: 10px;
    font-size: 16px;
    background: ${props => props.theme.colors.background2};
    color: ${props => props.theme.colors.text};
    resize: vertical;
    min-height: 200px;
    border: 1px solid ${props => props.theme.colors.text};
`;

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${props => props.theme.colors.text};
`;
