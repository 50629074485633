import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './reducers/store'; // Import your Redux store
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './utils/en.json';
import haTranslation from './utils/ha.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detects user's language
  .init({
    resources: {
      en: { translation: enTranslation },
      ha: { translation: haTranslation }
    },
    lng: localStorage.getItem('language') || 'ha', // Retrieve language from localStorage or default to 'en'
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
     <Provider store={store}>
    <App />
     </Provider>
   </React.StrictMode>
);


reportWebVitals();
