import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

const BookContainer = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${props => props.theme.colors.background2 || '#fff'};
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  font-family: 'Georgia', serif;
  position: relative;
  min-height: 300px;
`;

const Page = styled.div`
  padding: 10px;
  color: ${props => props.theme.colors.text || '#000'};
  background-color: ${props => props.theme.colors.background2 || '#fff'};
  min-height: 300px;
  box-sizing: border-box;
`;

const Pagination = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

const PageButton = styled.button`
  background: ${props => (props.active ? '#000' : '#ccc')};
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
`;

const Header = styled.div`
  display: flex;
  color: ${props => props.theme.colors.text || '#000'};
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;

  & h4 {
    //margin: 0;
    //flex-grow: 1;
    //text-align: center;
    color: ${props => props.theme.colors.text || '#000'};
    // ${props => (props.currentPage === 0 ? '' : 'visibility: hidden;')};
  }

  & .info {
    text-align: right;
    flex-shrink: 0;
    color: ${props => props.theme.colors.text || '#000'};
  }
`;

const Span = styled.span`
  padding: 5px;
  background-color: ${props => props.theme.colors.primary || '#fff'};
  border-radius: 10px;
  color: ${props => props.theme.colors.white || '#fff'};
`;

const PaginationWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
`;

const BookReader = ({ content, title, totalRead }) => {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const charactersPerPage = 1000; // Adjust as needed

  useEffect(() => {
    const createPages = (text, charsPerPage) => {
      const pagesArray = [];
      let startIndex = 0;
      while (startIndex < text.length) {
        let endIndex = startIndex + charsPerPage;
        pagesArray.push(text.substring(startIndex, endIndex));
        startIndex = endIndex;
      }
      return pagesArray;
    };

    const strippedContent = content.replace(/<[^>]+>/g, '');
    setPages(createPages(strippedContent, charactersPerPage));
  }, [content]);

  const handleSwipe = (direction) => {
    if (direction === 'LEFT' && currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'RIGHT' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('LEFT'),
    onSwipedRight: () => handleSwipe('RIGHT'),
  });

  return (
    <BookContainer {...swipeHandlers}>
      <Header currentPage={currentPage}>
        <h4>{title}</h4>
        <div className="info">
          {currentPage > 0 && <p>Page: <Span>{currentPage + 1}</Span></p>}
          {/* <p>{totalRead} Reads</p> */}
        </div>
      </Header>
      <Page dangerouslySetInnerHTML={{ __html: pages[currentPage] }} />
      <PaginationWrapperDiv>
      <Pagination>
        {pages.map((_, index) => (
          <PageButton
            key={index}
            active={index === currentPage}
            onClick={() => setCurrentPage(index)}
          >
            {index + 1}
          </PageButton>
        ))}
      </Pagination>
      </PaginationWrapperDiv>
    </BookContainer>
  );
};

export default BookReader;


