import React, { useContext } from 'react';
import styled from 'styled-components';
import useLogout from '../utils/useLogout';
import { FaHome, FaWallet, FaExchangeAlt, FaKey, FaReceipt, FaTags, FaBell, FaUser, FaShareAlt, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdLightMode } from "react-icons/md";
import { MdNightlight } from "react-icons/md";
import { ThemeContext } from '../App';
import { RiEnglishInput } from "react-icons/ri";
import { GiNigeria } from "react-icons/gi";
import logo from '../assets/Arewamega.png';

// Styled components
const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 60%;
  height: 100vh; /* Full height of the viewport */
  background-color: ${(props) => props.theme.colors.background2};
  color: ${(props) => props.theme.colors.text};
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1100;
  transition: transform 0.3s ease-in-out;
  overflow-y: auto; /* Enable scrolling */
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(-100%)')};
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }

  h4 {
    margin: 0;
    font-size: 16px;
  }
`;

const LogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px; /* Add space below the logo */
  img {
    width: 100px;
    height: 100px;
  }
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ensure the list grows to take available space */
  overflow-y: auto; /* Enable scrolling for the menu */
  margin-bottom: 20px;
`;

const Spacer = styled.div`
  height: 80px;
`; 

// Menu component
const Menu = ({ show, onClose }) => {
  const logoutUser = useLogout();
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
  };

  const ThemeToggle = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    
    return (
      <div onClick={toggleTheme}>
        {theme === 'light' ? <MdNightlight size={20} /> : <MdLightMode size={20}/>}
      </div>
    );
  };

  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Store selected language in localStorage
  };

  return (
    <>
      <ModalOverlay onClick={onClose} />
      <Container show={show}>
      <LogoDiv onClick={() => navigate('/')}><img src={logo} alt='logo' width={100} height={100}/></LogoDiv>
        <MenuList>
          <MenuItem onClick={() => navigate('/')}>
            <h4>Home</h4>
            <FaHome size={20} color='green'/>
          </MenuItem>
          <MenuItem onClick={() => navigate('/fund-wallet')}>
            <h4>Fund Wallet</h4>
            <FaWallet size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/Services')}>
            <h4>Wallet Transfer</h4>
            <FaExchangeAlt size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/Services')}>
            <h4>Data Pin</h4>
            <FaKey size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/transactions')}>
            <h4>Transactions</h4>
            <FaReceipt size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/Services')}>
            <h4>Pricing</h4>
            <FaTags size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/Services')}>
            <h4>Notifications</h4>
            <FaBell size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/profile')}>
            <h4>Profile</h4>
            <FaUser size={20} />
          </MenuItem>
          <MenuItem onClick={() => navigate('/Services')}>
            <h4>Referrals</h4>
            <FaShareAlt size={20} />
          </MenuItem>
          {/* <MenuItem onClick={() => navigate('/Lissafin-rayuwa')}>
            <h4>Lissafin Rayuwa</h4>
            <FaShareAlt size={20} />
          </MenuItem> */}
          <MenuItem onClick={() => handleLanguageChange('ha')}>
            <h4>Hausa</h4>
            <GiNigeria size={20} color='green'/>
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange('en')}>
            <h4>English</h4>
            <RiEnglishInput size={20} color='goldenod'/>
          </MenuItem>
          <MenuItem onClick={() => navigate('/ContactUs')}>
            <h4>Contact Us</h4>
            <FaShareAlt size={20} />
          </MenuItem>
          <ThemeToggle />
          <MenuItem onClick={handleLogout}>
            <h4>Logout</h4>
            <FaSignOutAlt size={20} color='red'/>
          </MenuItem>
        </MenuList>
        <Spacer />
      </Container>
    </>
  );
};

export default Menu;
