import React from 'react';
import { ModalContent, ShowModelContainer} from './ShowStatusModel.Styles';
import {Close, CloseIcon} from './PublicStyles';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

const ShowStatusModel = ({ message, onClose }) => {
  const navigate = useNavigate();

  const handleAddFund = () => {
    navigate('/add-fund');
  }
    return (
      <ShowModelContainer>
        <ModalContent>
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
          <p style={{color: 'red'}}>{message}</p>
          {message === 'Insufficient funds' && (
            <>
            <p>Your Wallet Balance is: <b>N0.00</b></p>
            <p><b>Click Here To Fund Your Wallet</b> <Button onClick={handleAddFund}>+</Button></p>
            </>
          )}
          <Button onClick={onClose}>Close</Button>
        </ModalContent>
      </ShowModelContainer>
      
    );
  };

export default ShowStatusModel;
