import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import Button from './Button';

const ConfirmPhoneModal = ({ onConfirm, phone, network, onClose }) => {
    const handleConfirmClick = (confirm) => {
      onConfirm(confirm);
      onClose(); // Close the modal
    };
  
    const handleCloseClick = () => {
      onClose();
    };
  
    return (
      <ModalOverlay onClick={handleCloseClick}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <h2>Confirm Phone Number</h2>
            <CloseDiv onClick={handleCloseClick}>
            <MdClose size={24} color='#fff'/>
          </CloseDiv>
          </ModalHeader>
          <ModalBody>
            <p>
              Are You Sure this phone number is correct <b>{phone}</b> ? And Network is {network}?
            </p>
          </ModalBody>
        <ButtonsDiv>
          <Button onClick={() => handleConfirmClick(true)}>Yes</Button>
          {/* <Button onClick={handleCloseClick} style={{backgroundColor: 'red'}}>No</Button> */}
        </ButtonsDiv>
        </ModalContainer>
      </ModalOverlay>
    );
  };
  
  export default ConfirmPhoneModal;

// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1349;
`;

const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1350;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div`
  margin: 20px 0px;
  text-align: center;
  color: ${(props) => props.theme.colors.text};
`;

const CloseDiv = styled.div`
  background-color: red;
  border-radius: 10px;
  position: absolute;
  font-weight: 800;
  right: 0;
  margin-right: 20px;
  padding: 1px;
  cursor: pointer;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
