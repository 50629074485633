import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { MdLightMode } from "react-icons/md";
import { MdNightlight } from "react-icons/md";
import { ThemeContext } from '../App';

const HeaderContainer = styled.div`
  padding: 15px;
  display: flex;
  aling-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.background2};
  color: ${(props) => props.theme.colors.text};
`;

const BrandDiv = styled.div`
  cursor: pointer
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const LightAndDarkModeDiv = styled.div`

`;

const Header = () => {
  const navigate = useNavigate();

  const ThemeToggle = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    
    return (
      <div onClick={toggleTheme}>
        {theme === 'light' ? <MdNightlight size={20} /> : <MdLightMode size={20}/>}
      </div>
    );
  };

  return (
    <HeaderContainer>
      <BrandDiv onClick={() => {navigate('/')}}>
      <h2>Arewa <Span>Mega</Span></h2>
      </BrandDiv>
      <LightAndDarkModeDiv>
        <ThemeToggle />
      </LightAndDarkModeDiv>
    </HeaderContainer>
  );
}

export default Header;