import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import logo from '../../assets/Arewamega.png';
import Button from '../Button';
import { FaCircleChevronDown } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";

const TransactionReceipt = ({ transaction, isFromComponent, onClose }) => {
  const [isMonnify, setIsMonnify] = useState(false);

  useEffect(() => {
    if (transaction && transaction.network === 'Monnify') {
      setIsMonnify(true);
    }
  }, [transaction]);

  if (!transaction) {
    return <div>No receipt available</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const pad = (num) => (num < 10 ? `0${num}` : num);
  
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    return `${day}/${month}/${year} ${pad(hours)}:${minutes}:${seconds} ${ampm}`;
  };

  const handleSaveAsImage = () => {
    const transactionDetails = document.getElementById('transaction-details');
    transactionDetails.classList.add('hide-save-and-close');

    setTimeout(() => {
      html2canvas(transactionDetails).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'Arewa_Gida_transaction-details.png';
        link.click();
        
        transactionDetails.classList.remove('hide-save-and-close');
      });
    }, 100);
  };

  return (
    <Overlay>
      <ReceiptContainer>
        <Card id="transaction-details">
            <ImageAndTitleDiv>
          <img src={logo} alt="Company Logo" width="100px" height="100px" />
          <h3>Arewa Mega Transaction Details</h3>
          </ImageAndTitleDiv>
          <Hr />
          <DetailsDiv>
          <p><strong>Network:</strong> {transaction.network}</p>
          <p><strong>Service Type:</strong> <b>{transaction.service_type}</b></p>
          <p><strong>Amount:</strong> ₦{isMonnify ? transaction.net_amount : transaction.amount}</p>
          {isMonnify ? null : <p><strong>Phone:</strong> {transaction.phone}</p>}
          <p>
          <strong>Status:</strong> {transaction.status}{' '}
              {transaction.status === 'success' ? (
                <FaCircleChevronDown color="green" />
              ) : (
                <MdCancel color="red" />
              )}
            </p>
          <p><strong>Reference:</strong> {transaction.ref}</p>
          <p><strong>Date:</strong> {formatDate(transaction.created_at)}</p>
          </DetailsDiv>
        </Card>

        <ButtonsDiv>
          <Button onClick={handleSaveAsImage}>Save</Button>
          <Button onClick={onClose} style={{backgroundColor: 'red'}}>Close</Button>
        </ButtonsDiv>
      </ReceiptContainer>
    </Overlay>
  );
};

export default TransactionReceipt;

// Styled components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1350;
`;

const ReceiptContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const ImageAndTitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center

    h4 {
        color: ${(props) => props.theme.colors.text};
    }
`;

const Hr = styled.hr`
    color: ${(props) => props.theme.colors.text};
    margin: 10px 0px;
`;

const DetailsDiv = styled.div`
    display: flex-start;
    margin: 10px 0px;
    color: ${(props) => props.theme.colors.text};

    p {
        margin: 10px 0px;
    }
`;

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
