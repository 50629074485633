import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
`;

const TermsTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const TermsText = styled.p`
    margin-bottom: 10px;
    line-height: 1.6;
    color: ${props => props.theme.colors.text};
`;


const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const TermsAndConditionsSignup = ({ onClose, iAgree }) => {

    const handleIagree = () => {
        iAgree();
        onClose();
    }

    return (
        <TermsContainer>
            <TermsTitle>Terms and Conditions</TermsTitle>
            <TermsText>
                By signing up, you agree to the following terms and conditions of Arewa Mega:
            </TermsText>
            <TermsText>
                1. You may purchase airtime and internet data for various mobile network providers through the Arewa Mega platform.
            </TermsText>
            <TermsText>
                2. All purchases made on the Arewa Mega platform are final, and no refunds are issued. Please ensure the accuracy of your transaction before proceeding.
            </TermsText>
            <TermsText>
                3. Prices for airtime and data plans may change at any time without prior notice.
            </TermsText>
            <TermsText>
                4. The delivery of airtime or data purchases is typically instant but may be delayed due to network issues. In the event of delays, please contact customer service for support.
            </TermsText>
            <TermsText>
                5. You are responsible for maintaining the confidentiality of your account information. Any activity under your account is your responsibility.
            </TermsText>
            <TermsText>
                6. Arewa Mega is not responsible for any loss or damage resulting from network provider issues or service interruptions.
            </TermsText>
            <TermsText>
                7. By using the platform, you agree to comply with all local laws and regulations.
            </TermsText>
            <TermsText>
                8. Arewa Mega reserves the right to modify these terms at any time, and continued use of the platform implies acceptance of the updated terms.
            </TermsText>

            <ButtonsDiv>
                <Button onClick={handleIagree}>
                    I Agree 
                </Button>
                <Button onClick={onClose} style={{ background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </TermsContainer>
    );
};

export default TermsAndConditionsSignup;
