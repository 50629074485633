import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../config';
import { FaWhatsapp, FaWifi, FaPhone, FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: ${(props) => props.theme.colors.background2};
    color: ${(props) => props.theme.colors.text};
    height: 60px;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
    z-index: 1010;
`;

const ItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 5px;


    h4 {
        color: ${(props) => props.theme.colors.text};
    }
`;

const IconDiv = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 7px;
    color: ${(props) => props.theme.colors.text};
    padding: 5px;
`;

const Footer = ({ show }) => {
  const [supportInfo, setSupportInfo] = useState({ email: '', whatsapp_number: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchSupportInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/public/support/get/info`);
        setSupportInfo(response.data);
      } catch (error) {
        setError('Error fetching support information');
      }
    };
    fetchSupportInfo();
  }, []);

  const openWhatsApp = (whatsappNumber) => {
    if (whatsappNumber) {
      const whatsappURL = `https://wa.me/${whatsappNumber}`;
      window.open(whatsappURL, '_blank');
    } else {
      setError('WhatsApp number is not available');
    }
  };

  return (
    <FooterContainer>
      {/* {error && <p>{error}</p>} */}
      <ItemDiv onClick={() => openWhatsApp(supportInfo.whatsapp_number)}>
        <FaWhatsapp size={24} />
        <h4>Contact</h4>
      </ItemDiv>
      <ItemDiv onClick={() => navigate('/data-purchase')}>
        <FaWifi size={24} />
        <h4>Data</h4>
      </ItemDiv>
      <ItemDiv onClick={() => navigate('/airtime-purchase')}>
        <FaPhone size={24} />
        <h4>Airtime</h4>
      </ItemDiv>
      <ItemDiv onClick={show}>
        <FaBars size={24} />
        <h4>Menu</h4>
      </ItemDiv>
    </FooterContainer>
  );
};

export default Footer;
