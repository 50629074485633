import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import ErrorMessageModal from './ErrorMessageModal';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  boder-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.text};
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  gap: 10px
`;

const ConsentBanner = () => {
  const [consentGiven, setConsentGiven] = useState(localStorage.getItem('consentGiven') === 'true');
  const [error, setError] = useState('');

  const handleConsent = () => {
    localStorage.setItem('consentGiven', 'true');
    setConsentGiven(true);
  };

  const handleConsentNo = () => {
    setError('Ok Bamatsala, idan kana so alamar sanarwar dake can sama ta Dauke ka danna dayar Alamar zaka ga ta Rufe.');
    setTimeout(() => {
      setError('');
    }, 7000);
  };

  if (consentGiven) {
    return null;
  }

  return (
    <Container>
      <TitleDiv>
      <p>We use cookies and other methods to collect data about your device to provide a better experience. By using our site, you consent to our privacy policy.</p>
      </TitleDiv>
      <ButtonsDiv>
      <Button onClick={handleConsent}>I Agree</Button>
      <Button onClick={handleConsentNo} style={{backgroundColor: 'red'}}>No</Button>
      </ButtonsDiv>

      {error && (
      <ErrorMessageModal errorMessage={error} onClose={() => {setError('')}}/>
      )}  
    </Container>
  );
};

export default ConsentBanner;
