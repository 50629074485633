import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import styled, { useTheme } from 'styled-components';

const ChartForAdmin = ({ data, filter }) => { 
    const theme = useTheme();

    const totalViews = data.views.reduce((acc, view) => acc + view.count, 0);
    const trailerViews = data.views.find(view => view.view_type === 'trailer')?.count || 0;
    const mainViews = data.views.find(view => view.view_type === 'main')?.count || 0;

    const chartData = {
        labels: ['Total Views', 'Trailer Views', 'Main Views', 'Total Sells', 'Total Follows', 'Total Earnings'],
        datasets: [
            {
                label: `${filter.charAt(0).toUpperCase() + filter.slice(1)} Analytics`,
                data: [totalViews, trailerViews, mainViews, data.sells, data.follows, data.earnings],
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: theme.colors.text, // Apply theme color
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${value}`;
                    },
                },
                bodyColor: theme.colors.text, // Tooltip text color
                backgroundColor: 'rgba(0,0,0,0.7)', // Tooltip background color
            },
        },
        scales: {
            x: {
                ticks: {
                    color: theme.colors.text, // X-axis label color
                },
                grid: {
                    color: 'rgba(255,255,255,0.2)', // X-axis grid line color
                },
            },
            y: {
                ticks: {
                    color: theme.colors.text, // Y-axis label color
                },
                grid: {
                    color: 'rgba(255,255,255,0.2)', // Y-axis grid line color
                },
            },
        },
    };

    return (
        <Container>
            <StyledBar data={chartData} options={chartOptions} />
        </Container>
    );
};

const Container = styled.div`
    height: 233px;
    width: 100%;
    margin: 10px;
    background-color: ${props => props.theme.colors.background}; // Background color
    color: ${props => props.theme.colors.text}; // Text color
    border-radius: 10px; // Border radius (if needed)
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledBar = styled(Bar)`
    height: 100%;
    width: 100%;
`;

export default ChartForAdmin;
