import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

const Container = styled.div`
  display: grid;
  height: 100vh;
  max-width: 100vw;
  margin: 0 auto;
  gap: 1rem;
  overflow: auto;
  position: relative;
  //overflow-x: hidden;
  //grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: 300px 1fr; /* Adjusted for desktop view */
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjusted for mobile view */
  }
`;


const Aside = styled.aside`
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  position: sticky; /* Stick to the top */
  top: 0; /* Stick to the top */
  width: 300px; /* Set a fixed width for the aside */
  background-color: ${(props) => props.theme.colors.background}; 
  color: ${(props) => props.theme.colors.text};
  z-index: 1000; /* Ensure it appears above other content */
  
  @media (max-width: 768px) {
    display: none;
  }
`;


const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-top: 1.4rem;

  h2 {
    color: ${props => props.theme.colors.text};
  }
`;

const Logo = styled.div`
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  border-radius: 50%;
  overflow: hidden;
  border-bottom: 2px solid black;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Close = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.colors.danger};

  @media (max-width: 768px) {
    display: block;
    cursor: pointer; 
  }

`;

// Keyframes for the animation
const slideDown = keyframes`
0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Sidebar = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; /* Toggle display based on isOpen */
  flex-direction: column;
  position: relative;
  top: 1rem;
  //border: 2px solid ${props => props.theme.colors.text};
  border-radius: 6px;
  padding: 10px;
  margin-left: 5px
  z-index: 1000;
  animation: ${({ isOpen }) => (isOpen ? slideDown : 'none')} 3s all ease-in-out; /* Apply animation based on isOpen */

  // @media (min-width: 769px) {
  //   display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; /* Toggle display based on isOpen */
  // }
`;


const SidebarLink = styled(Link)`
  display: flex;
  color: ${props => props.theme.colors.text};
  margin: 1;
  gap: 1rem;
  position: relative;
  height: 3.5rem;
  transition: all 300ms ease;
  align-items: center;
  text-decoration: none;

  // &:last-child {
  //   position: absolute;
  //   bottom: 2rem;
  //   width: 100%;
  //   cursor: pointer;
  // }

  &:active {
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.primary};
    margin-left: 1px;

    &:before {
      content: '';
      width: 6px;
      height: 100%;
      background-color: ${props => props.theme.colors.primary};
    }

    span {
      color: ${props => props.theme.colors.primary};
      margin-left: calc(1rem - 3px);
    }
  }

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  &:hover span {
    margin-left: 1rem;
  }
`;

const Main = styled.main`
  //height: calc(100vh - 1.4rem); /* Subtract margin-top from viewport height */
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */

  // /* Additional styling based on your design */
  // padding: 1.4rem; /* Add padding as needed */
  max-width: 100vw;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #0004;
    visibility: hidden;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  @media (max-width: 1024px) {
    &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.3rem;
    border-radius: 50%;
  }
  }

  @media (max-width: 768px) {
    &::-webkit-scrollbar {
    width: 0;
    height: 0;
    border-radius: 50%;
  }
  }

  @media (max-width: 480px) {
    &::-webkit-scrollbar {
    width: 0;
    height: 0;
    border-radius: 50%;
  }
  }

  @media (max-width: 290px) {
    &::-webkit-scrollbar {
    width: 0;
    height: 0;
    border-radius: 50%;
  }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
`;

const CategoriesContainer = styled.div`

`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 10px;
  outline: none;
`;

const SearchIcon = styled(FaSearch)`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 10px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  width: 30px;
  height:100%;
`;


const DateWrapper = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors.light};
  border-radius: ${props => props.theme.borderRadius.radius1};
  margin-top: 1rem;
  padding: ${props => props.theme.padding.padding1};

  input[type='date'] {
    background: transparent;
    color: ${props => props.theme.colors.text};
  }
`;

const InsightsContainer = styled.div`
  display: grid;
  grid-template-columns: 300px, 1fr; //repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.6rem;
  overflow: auto;

  > div {
    padding: ${props => props.theme.padding.card};
    border-radius: ${props => props.theme.borderRadius.card};
    margin-top: 1rem;
    box-shadow: ${props => props.theme.boxShadow};
    transition: all 300ms ease;
    justify-content: center;
    //overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items to center horizontally */
    text-align: center; /* Align text content to center */

    &:hover {
      box-shadow: none;
      border: 2px solid green;
    }

    &:active {
      box-shadow: none;
      border: 6px solid green;
    }

    span {
      background-color: ${props => props.theme.colors.primary};
      padding: 0.5rem;
      border-radius: 50%;
      color: ${props => props.theme.colors.white};
      font-size: 2rem; /* Adjusted icon size */
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      margin: 1rem 0 0.6rem;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.text};
    }

    h1 {
      color: ${props => props.theme.colors.text};
    }

    small {
      display: block;
      margin-top: 1.3rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* repeat(auto-fit, minmax(150px, 1fr));  150px Two items side by side on mobile */

    h3 {
      font-size: .8rem;
    }

    h1 {
      font-size: 1rem;
    }
  }
`;


const MenuIcon = styled.div`
  display: none;
  color: ${props => props.theme.colors.text}; 

  @media (max-width: 768px) {
    // display: block;
    // cursor: pointer;
    display: none;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 15px;
    padding: 8px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
    padding: 5px;
  }

  @media (max-width: 290px) {
    margin-top: 5px;
    padding: 3px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 8px;
  }

  @media (max-width: 480px) {
    gap: 5px;
  }

  @media (max-width: 290px) {
    gap: 3px;
  }

  .active {
    color: #28a745;
    font-weight: bold;
  }
`;

const PageNumber = styled.div`
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.background2};
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 0.9rem;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 768px) {
    padding: 7px 10px;
    margin: 0 3px;
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    padding: 5px 8px;
    margin: 0 2px;
    font-size: 0.8rem;
  }

  @media (max-width: 290px) {
    padding: 4px 6px;
    margin: 0 1px;
    font-size: 0.75rem;
  }
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 0.9rem;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 7px 10px;
    margin: 0 3px;
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    padding: 5px 8px;
    margin: 0 2px;
    font-size: 0.8rem;
  }

  @media (max-width: 290px) {
    padding: 4px 6px;
    margin: 0 1px;
    font-size: 0.75rem;
  }
`;


export {
  Container,
  Aside,
  Top,
  Logo,
  Close,
  Sidebar,
  SidebarLink,
  Main,
  HeaderContainer,
  CategoriesContainer,
  InputContainer,
  SearchInput,
  SearchIcon,
  DateWrapper,
  InsightsContainer,
  MenuIcon,
  PaginationWrapper,
  Pagination,
  PageNumber,
  PaginationButton
};
