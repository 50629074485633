import React, {useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaPhone, FaWifi } from "react-icons/fa";
import Header from '../Header';
import Footer from '../Footer';
import Menu from '../Menu';

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    //display: flex;
    //fex-direction: column:
    align-items: center;
    justify-content: center;
`;

const MenuSection = styled.div`

`;

const MainAnnouncements = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    background: ${props => props.theme.colors.background2};
    color: ${props => props.theme.colors.text};
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.text};
    border-radius: 10px;
`;

const FieldDiv = styled.div`
    background: ${props => props.theme.colors.background2};
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    margin: 10px 5px;
    border: 1px solid ${props => props.theme.colors.text};
    border-radius: 10px;
`;

const ItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 5px;


    h4 {
        color: ${(props) => props.theme.colors.text};
    }
`;

const IconDiv = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 7px;
    color: ${(props) => props.theme.colors.text};
    padding: 5px;
`;

const ParentPadding = styled.div`
    padding: 20px;
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: ${props => (props.componentIsPending ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
`;

const PlaceHolder = () => {
    const [showMenu, setShowMenu] =  useState(false);
    const navigate = useNavigate();

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
      }

    return (
        <>
        <Header />
        {showMenu && (
        <MenuSection>
          <Menu show={handleShowMenu} onClose={handleShowMenu}/>
        </MenuSection>
      )}
    <ParentPadding>
        <Container>
        <MainAnnouncements>
        <div style={{display: 'flex', alignItems: 'center', gap: '10px', margin: '10px'}}>
            <h3>This page is under maintenance</h3> 
            <AudioIndicator componentIsPending={true} />
        </div>
        <div>
            <p>You can purchase data or airtime for now. Click the option below to navigate.</p>
        </div>
        </MainAnnouncements>


            <FieldDiv>
            <ItemDiv onClick={() => {navigate('/airtime-purchase')}}>
                <IconDiv><FaPhone size={24} /></IconDiv>
                <h4>Airtime</h4>
            </ItemDiv>

            <ItemDiv onClick={() => {navigate('/data-purchase')}}>
                <IconDiv><FaWifi size={24} /></IconDiv>
                <h4>Data</h4>
            </ItemDiv>
            </FieldDiv>
            
        </Container>
        </ParentPadding>
        <Footer show={handleShowMenu} />
        </>
    );
}

export default PlaceHolder;