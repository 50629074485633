// MainDashboardStyles.js

import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: grid;
  align-items: start; /* Adjust alignment to start */
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px;
  gap: 1.8rem;
  grid-template-columns: 300px 1fr; /* Adjusted for desktop view */
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjusted for mobile view */
  }
`;


const Aside = styled.aside`
//height: 100%;
//overflow-y: auto;
position: relative;
}
  
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;

  h2 {
    color: ${props => props.theme.colors.text};
  }
`;

const Logo = styled.div`
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  border-radius: 50%;
  overflow: hidden;
  border-bottom: 2px solid black;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Close = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.colors.danger};

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }

`;

// Keyframes for the animation
const slideDown = keyframes`
0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Sidebar = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; /* Toggle display based on isOpen */
  flex-direction: column;
  //height: 100%;
  position: relative;
  top: 1rem;
  border: 2px solid ${props => props.theme.colors.text};
  border-radius: 6px;
  padding: 10px;
  z-index: 1000;
  animation: ${({ isOpen }) => (isOpen ? slideDown : 'none')} 3s all ease-in-out; /* Apply animation based on isOpen */

  // @media (min-width: 769px) {
  //   display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; /* Toggle display based on isOpen */
  // }
`;


const SidebarLink = styled(Link)`
  display: flex;
  color: ${props => props.theme.colors.text};
  margin: 1;
  gap: 1rem;
  position: relative;
  height: 3.5rem;
  transition: all 300ms ease;
  align-items: center;
  text-decoration: none;

  // &:last-child {
  //   position: absolute;
  //   bottom: 2rem;
  //   width: 100%;
  //   cursor: pointer;
  // }

  &:active {
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.primary};
    margin-left: 1px;

    &:before {
      content: '';
      width: 6px;
      height: 100%;
      background-color: ${props => props.theme.colors.primary};
    }

    span {
      color: ${props => props.theme.colors.primary};
      margin-left: calc(1rem - 3px);
    }
  }

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  &:hover span {
    margin-left: 1rem;
  }
`;

const Main = styled.main`
  margin-top: 1.4rem;
`;

const DatePickerDiv = styled.div`

`;

const FilterDiv = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    label {
        margin-right: 10px;
    }
    select {
        padding: 5px;
    }
`;

const DateWrapper = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors.light};
  border-radius: ${props => props.theme.borderRadius.radius1};
  margin-top: 1rem;
  padding: ${props => props.theme.padding.padding1};

  input[type='date'] {
    background: transparent;
    color: ${props => props.theme.colors.text};
  }
`;

const InsightsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.6rem;

  > div {
    padding: ${props => props.theme.padding.card};
    border-radius: ${props => props.theme.borderRadius.card};
    margin-top: 1rem;
    box-shadow: ${props => props.theme.boxShadow};
    transition: all 300ms ease;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items to center horizontally */
    text-align: center; /* Align text content to center */

    &:hover {
      box-shadow: none;
      border: 2px solid green;
    }

    &:active {
      box-shadow: none;
      border: 6px solid green;
    }

    span {
      background-color: ${props => props.theme.colors.primary};
      padding: 0.5rem;
      border-radius: 50%;
      color: ${props => props.theme.colors.white};
      font-size: 2rem; /* Adjusted icon size */
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      margin: 1rem 0 0.6rem;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.text};
    }

    h1 {
      color: ${props => props.theme.colors.text};
    }

    small {
      display: block;
      margin-top: 1.3rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Two items side by side on mobile */

    h3 {
      font-size: .8rem;
    }

    h1 {
      font-size: 1rem;
    }
  }
`;

// const DropdownMenu = styled.div`
//   display: ${props => (props.open ? 'flex' : 'none')};
//   //display: flex;
//   flex-direction: column;
//   position: absolute; 
//   padding: 15px;
//   top: 1rem;
//   left: 0;
//   right: 0;
//   background-color: ${props => props.theme.colors.background};
//   border: 1px solid ${props => props.theme.colors.border};
//   border-radius: 8px;
//   z-index: 1;
 

//   @media (max-width: 768px) {
//     display: ${(props) => (props.open ? 'block' : 'none')}; /* Show/hide dropdown on mobile */
//   }
// `;

const MenuIcon = styled.div`
  display: none;
  color: ${props => props.theme.colors.text}; 

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

export {
  Container,
  Aside,
  Top,
  Logo,
  Close,
  Sidebar,
  SidebarLink,
  Main,
  DateWrapper,
  InsightsContainer,
//  DropdownMenu,
  MenuIcon,
  DatePickerDiv,
  FilterDiv
};
