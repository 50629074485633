import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; // Adjust the import as necessary
import { API_URL } from '../../config'; // Adjust the import as necessary
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingIcon, SuccessIcon, ErrorIcon } from './styles';

const FormField = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const DataPlanAddNewForm = ({ onClose, refresh, refreshCounts }) => {
    const [network, setNetwork] = useState(null);
    const [planName, setPlanName] = useState(null);
    const [serviceType, setServiceType] = useState(null);
    const [validity, setValidity] = useState(null);
    const [buyingPrice, setBuyingPrice] = useState(null);
    const [userPrice, setUserPrice] = useState(null);
    const [agentPrice, setAgentPrice] = useState(null);
    const [vendorPrice, setVendorPrice] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginSuccess(false);
        setError('');
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                await axios.post(`${API_URL}/add/new/plan`, 
                    { datanetwork: network, planName, planId, type: serviceType, price: buyingPrice, userPrice, agentPrice, vendorPrice, day: validity }, config);
    
                setError('');
                setLoginSuccess(true);
                setIsLoading(false);

                setTimeout(() => {
                    setLoginSuccess(false);
                    onClose();
                    refresh();
                    refreshCounts();
                }, 2000); 
            } catch (error) {
                console.error('Error updating plan:', error);
                setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormField>
                <Label>Network</Label>
                <Select value={network} onChange={(e) => setNetwork(e.target.value)}>
                    <option value={''}>Choose Network</option>
                    <option value={1}>MTN</option>
                    <option value={2}>GLO</option>
                    <option value={3}>9MOBILE</option>
                    <option value={4}>AIRTEL</option>
                </Select>
            </FormField>
            <FormField>
                <Label>Service Type</Label>
                <Select value={serviceType} onChange={(e) => setServiceType(e.target.value)}>
                    <option value={''}>Choose Type</option>
                    <option value={'SME'}>SME</option>
                    <option value={'Corporate'}>CORPORATE</option>
                    <option value={'Gifting'}>GIFTING</option>
                </Select>
            </FormField>
            <FormField>
                <Label>Plan Id</Label>
                <Input
                    type="text"
                    value={planId}
                    onChange={(e) => setPlanId(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Name</Label>
                <Input
                    type="text"
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Validity</Label>
                <Input
                    type="tel"
                    value={validity}
                    onChange={(e) => setValidity(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Buying Price</Label>
                <Input
                    type="tel"
                    value={buyingPrice}
                    onChange={(e) => setBuyingPrice(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>User Price</Label>
                <Input
                    type="tel"
                    value={userPrice}
                    onChange={(e) => setUserPrice(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Agent Price</Label>
                <Input
                    type="tel"
                    value={agentPrice}
                    onChange={(e) => setAgentPrice(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Vendor Price</Label>
                <Input
                    type="tel"
                    value={vendorPrice}
                    onChange={(e) => setVendorPrice(e.target.value)}
                />
            </FormField>
            
            <ButtonsDiv>
            <Button onClick={handleSubmit} disabled={isLoading || loginSuccess}>
                
                {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Update Plan'}
                {isLoading && <LoadingIcon />}
            </Button>
            <Button onClick={onClose} style={{ marginTop: '10px', background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </form>
    );
};

export default DataPlanAddNewForm;
