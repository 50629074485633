import React, { useState } from 'react';
import styled from 'styled-components';
import {Close, CloseIcon} from './PublicStyles';

const ModalOverlay = styled.div`
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.overlay};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  position: relative;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9; 
`;


const TutorialVideoModel = ({ videoId, onClose }) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    onClose();
  };

  return (
    <>
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <Close onClick={closeModal}><CloseIcon /></Close>
            <ResponsiveIframe
              title="YouTube Video"
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allowFullScreen
            />
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default TutorialVideoModel;
