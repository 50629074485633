// themes.js

export const lightTheme = {
  colors: {
    primary: '#28a745', //green
    danger: '#ff5967', //red
    success: '#41f1b6', //light green
    warning: '#dbcf4d', //yellow
    white: '#fff', //white
    text: '#000',
    black: '#000',
    infoDark: '#7d8da1', //light gray
    infoLight: '#dce1eb', //lighter gray
    dark: '#55595c', //dark gray
    light: 'rgba(242, 243, 247, 0.18)', //offwhite
    primaryVariant: '#117d2a', //green dard
    darkVariant: '#677483',
    background: '#f6f6f9', //white 95%
    background2: '#e4e4eb',
    SkeletonBackground: '#f2f2fa',
  },
  borderRadius: {
    card: '2rem',
    radius1: '0.4rem',
    radius2: '0.8rem',
    radius3: '1.2rem',
  },
  padding: {
    card: '1.8rem',
    padding1: '1.2rem',
  },
  boxShadow: '0 2rem 3rem rgba(132, 139, 200, 0.18)',
};

export const darkTheme = {
  colors: {
    primary: '#28a745', //green
    danger: '#ff5967',  //red
    success: '#41f1b6', //light green
    warning: '#dbcf4d', //yellow 
    white: '#fff', // white
    text: '#fff',
    infoDark: '#7d8da1', //light gray 
    infoLight: '#dce1eb', //lighter gray
    dark: '#55595c', //dark gray
    light: 'rgba(0, 0, 0, 4)', //black
    primaryVariant: '#117d2a', //green dard
    darkVariant: '#677483',
    background: '#55595c', //dark gray
    background2: '#444',
    SkeletonBackground: '#61676b',
  },
  borderRadius: {
    card: '2rem',
    radius1: '0.4rem',
    radius2: '0.8rem',
    radius3: '1.2rem',
  },
  padding: {
    card: '1.8rem',
    padding1: '1.2rem',
  },
  boxShadow: '0 2rem 3rem rgba(0, 0, 0, 4)',
};
