import styled from 'styled-components';

const ShowModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  margin: 0;
  padding: 5px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.background};
  position: relative;
`;

const ModalBackdrop = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseButton = styled.span`
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover {
    color: #000;
    cursor: pointer;
  }
`;

const Button = styled.button`
  margin-top: 10px;
  align-items: center;
  display: flext;
  justify-content: center;
`;

export { ModalBackdrop, ModalContent, CloseButton, Button, ShowModelContainer};