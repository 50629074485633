import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; 
import { API_URL } from '../../config';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import DataPlanEditForm from './DataPlanEditForm';
import DataPlanAddNewForm from './DataPlanAddNewForm'; 
import useDebounce from '../../utils/useDebounce';
import Spinner from '../Spinner';
import { AudioIndicator, ActiveStatusAndEllipsesDiv, Actions, AdminActiveStatusDiv, 
   Ellipses, DropdownHeader, DropdownMenu, DropdownOption, CloseIcon } from '../ProducerAllPosts';
import { MdClose } from 'react-icons/md';
import { IoMdOptions } from "react-icons/io";
import { FaPlus } from "react-icons/fa";

const FormContainer = styled.div`
    position: relative;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
`;

const SpinnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const FormTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
    color: ${props => props.theme.colors.text};
`;

const SearchAndFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }
  button {
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SearchAndFilterIconDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text};

  input {
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }

`;

const PlanCard = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
`;

const ServiceTypediv = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

const Tabs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 0px;
    border-radius: 10px;
`;

const Tab = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    background: ${props => props.active ? props.theme.colors.primary : '#ddd'};
    color: ${props => props.active ? 'white' : 'black'};
    cursor: pointer;
    margin: 10px 0px;
    &:hover {
        background: #007bff;
        color: white;
    }
`;

const Input = styled.input`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 5px;
`;

const UserList = styled.div`
    margin-bottom: 20px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const AddIconDiv = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 20px;
    padding: 5px;
`;


const DataPlans = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allPlans: 0, mtn: 0, airtel: 0, glo: 0, nineMobile: 0 });
    const [tab, setTab] = useState('all');
    const [dropdownPlanId, setDropdownPlanId] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showAddNewPlanModal, setShowAddNewPlanModal] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const dropdownRef = useRef(null);
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 500); // 500ms debounce delay

    

    const fetchPlans = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/data-plans`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setPlans(response.data.plans);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setIsLoading(false);
                setShowFilter(false);
            }
        }
    }, [currentPage, debouncedSearch, tab, navigate]);

    const fetchCounts = useCallback(async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          };
          const response = await axios.get(`${API_URL}/plans/counts`, {
            params: { search: debouncedSearch, },
            ...config
          });
          setCounts(response.data);
        } catch (error) {
          console.error('Error fetching user counts:', error);
        }
      }
    }, [debouncedSearch, navigate]);

    useEffect(() => {
      fetchCounts();
      fetchPlans();
  }, [currentPage, debouncedSearch, tab, navigate, fetchPlans, fetchCounts]);

  const handleDelete = async (planId) => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    if (window.confirm('Are you sure you want to delete this ad?')) {
      try {
        await axios.delete(`${API_URL}/admin/plan/delete/${planId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }); 
        setPlans(plans.filter(plan => plan.pId !== planId)); 
        setIsDropdownOpen(false);
      } catch (error) {
        setIsDropdownOpen(false);
        console.error('Error deleting ad:', error);
      }
    }
  }
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

    const handleSelectPlan = (plan) => {
        if (selectedPlan && selectedPlan.plan.pId === plan.pId) {
            setSelectedPlan(null);
        } else {
            setSelectedPlan(plan);
        }
    };

    const handleClosePlan = () => {
        setSelectedPlan(null);
        setDropdownPlanId(null);
        setIsDropdownOpen(false);
    };

    const handleShowAddNewPlanModal = () => {
      setShowAddNewPlanModal(!showAddNewPlanModal);
    };

    const handleCloseShowAddNewPlanModal = () => {
      setShowAddNewPlanModal(!showAddNewPlanModal);
    };

    const handleDropdownClick = (pId) => {
      if (dropdownPlanId === pId && isDropdownOpen) {
        setIsDropdownOpen(false);
        setDropdownPlanId(null);
      } else {
        setIsDropdownOpen(true);
        setDropdownPlanId(pId);
      }
    };

    const handleUpdatePlanStatus = (pId) => {
      setDropdownPlanId(null);
      setIsDropdownOpen(false);
    };
  
    const handleCloseDropdown = () => {
      setDropdownPlanId(null);
      setIsDropdownOpen(false);
    };

    const scrollToTop = () => {
      ListRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const handlePageClick = (page) => {
      setCurrentPage(page);
      scrollToTop();
  };

  const handleNextPage = () => {
      if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          scrollToTop();
      }
  };

  const handlePreviousPage = () => {
      if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scrollToTop();
      }
  };

  const handleTabChange = (tab) => {
      setTab(tab);
      setCurrentPage(1);
  };

  // Function to convert network names to numbers
  const mapNetworkIdToName = (datanetwork) => {
    switch (datanetwork) {
      case 1:
        return 'MTN';
      case 2:
        return 'GLO';
      case 3:
        return '9MOBILE';
      case 4:
        return 'AIRTEL';
      default:
        return null;
    }
  };

  const formatWallet = (wallet) => {
    // Check if wallet is a valid number or string that can be converted to a number
    if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
      return 'Invalid earnings';
    }

    // Convert earnings to number and ensure it's a valid number
    wallet = parseFloat(wallet);
    if (isNaN(wallet) || !isFinite(wallet)) {
      return 'Invalid wallet';
    }

    // Convert earnings to string with 2 decimal places
    let walletStr = wallet.toFixed(2);

    // Split wallet into whole and decimal parts
    const parts = walletStr.split('.');
    let wholePart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Add commas for thousands, millions, etc.
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    wholePart = wholePart.replace(regex, ',');

    // Return formatted earnings
    return wholePart + decimalPart;
  };

    if (isLoading) {
        return (
            <SpinnerContainer>
                <Spinner />
            </SpinnerContainer>
        );
    }

    return (
        <FormContainer>
            <FormTitle><h2>Plans Management</h2> <AddIconDiv onClick={handleShowAddNewPlanModal}><FaPlus color='white' size={20}/></AddIconDiv></FormTitle>

    <SearchAndFilter>
          <SearchAndFilterIconDiv>
              <Input
                type="text"
                placeholder="Search by name or plan id"
                value={search}
                onChange={handleSearchChange}
              />
              <div onClick={handleShowFilter}><IoMdOptions size={30} width={100} height={100} /></div>
            </SearchAndFilterIconDiv>
            {showFilter && (
              <>
                <Tabs>
              <Tab active={tab === 'all'} onClick={() => handleTabChange('all')}>
                All Plans ({counts.allPlans})
              </Tab>
              <Tab active={tab === 'mtn'} onClick={() => handleTabChange('mtn')}>
                Mtn ({counts.mtn})
              </Tab>
              <Tab active={tab === 'airtel'} onClick={() => handleTabChange('airtel')}>
                Airtel ({counts.airtel})
              </Tab>
              <Tab active={tab === 'glo'} onClick={() => handleTabChange('glo')}>
                Glo ({counts.glo})
              </Tab>
              <Tab active={tab === '9mobile'} onClick={() => handleTabChange('9mobile')}>
                9mobile ({counts.nineMobile})
              </Tab>
            </Tabs>
              </>
            )}
            </SearchAndFilter>  
        
            <UserList ref={ListRef}>
                {plans.map(plan => (
                  <PlanCard key={plan.pId}>
                      <ServiceTypediv >Network: {mapNetworkIdToName(plan.datanetwork)}</ServiceTypediv>
                  <div onClick={() => handleSelectPlan(plan)}>
                      <p>Name: {plan.name}</p>
                      <p>Buying Price: ₦{formatWallet(plan.price)}</p>
                      <p>User Price: ₦{formatWallet(plan.userprice)}</p>
                  </div>
                  <ActiveStatusAndEllipsesDiv>
                      <AdminActiveStatusDiv adminStatus={plan.active_status}>
                      <p>{plan.active_status}</p> 
                      {plan.active_status === 'active' && <AudioIndicator postIsActive={plan.active_status}/>}
                      </AdminActiveStatusDiv>
                      <Ellipses onClick={() => handleDropdownClick(plan.pId)}>
                      <IoMdOptions />
                      </Ellipses>
                  </ActiveStatusAndEllipsesDiv>
                  <Actions ref={dropdownRef}>
                  {isDropdownOpen && dropdownPlanId === plan.pId ? (
                      <DropdownMenu onClick={(e) => e.stopPropagation()}>
                      <DropdownHeader>
                          <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                      </DropdownHeader>
                      <DropdownOption onClick={() => handleSelectPlan(plan)}>Edit</DropdownOption>
                      <DropdownOption onClick={() => handleDelete(plan.pId)}>Delete</DropdownOption>
                      <DropdownOption onClick={() => handleUpdatePlanStatus(plan.pId)}>Update Status</DropdownOption>
                      </DropdownMenu>
                  ) : null}
                  </Actions>
                  </PlanCard>
                ))}
            </UserList>
            {totalPages > 1 && (
                <PaginationWrapper>
                    <Pagination>
                        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </PaginationButton>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <PageNumber
                                key={index + 1}
                                onClick={() => handlePageClick(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </PageNumber>
                        ))}
                        <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </PaginationButton>
                    </Pagination>
                </PaginationWrapper>
            )}
            {selectedPlan && (
                <>
                    <ModalBackdrop onClick={handleClosePlan} />
                    <FormModelDiv>
                        <DataPlanEditForm
                            plan={selectedPlan}
                            onClose={handleClosePlan}
                            refresh={fetchPlans}
                            refreshCounts={fetchCounts}
                        />
                    </FormModelDiv>
                </>
            )}

            {showAddNewPlanModal && (
                <>
                    <ModalBackdrop onClick={handleCloseShowAddNewPlanModal} />
                    <FormModelDiv>
                        <DataPlanAddNewForm
                            onClose={handleCloseShowAddNewPlanModal}
                            refresh={fetchPlans}
                            refreshCounts={fetchCounts}
                        />
                    </FormModelDiv>
                </>
            )}
        </FormContainer>
    );
};

export default DataPlans;
