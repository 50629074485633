import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaEllipsisH  } from 'react-icons/fa';

const spinAnimation = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled(FaEllipsisH)`
  //background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  font-size: 40px;
  font-weight: 800;
  animation: ${spinAnimation} 1.4s infinite linear;
`;

const Spinner = () => {
  return <SpinnerWrapper />;
};

export default Spinner; 
