import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIcon, SuccessIcon, ErrorIcon } from '../components/Admin/styles';
import styled from 'styled-components';

// Define a styled button component
const StyledButton = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    gap: 8px;
    &:disabled {
        background-color: green;
        cursor: not-allowed;
    }
`;

const Button = ({ 
  onClick, 
  isLoading, 
  loginSuccess, 
  errorMessage, 
  uploadProgress, 
  disabled, 
  children,
  isP 
}) => {
  const renderIcon = () => {
    if (loginSuccess) {
      return <SuccessIcon color='#fff'/>;
    }
    if (errorMessage) {
      return <ErrorIcon />;
    }
    let title = 'Loading...';
    if (isP) {
        title = 'Processing...';
    }
    if (isLoading) {
      return (
        <>
          {title}
          <LoadingIcon />
          {/* <span>{uploadProgress}%</span> */}
        </>
      );
    }
    return children;
  };

  return (
    <StyledButton onClick={onClick} disabled={disabled || isLoading || loginSuccess}>
      {renderIcon()}
    </StyledButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  loginSuccess: PropTypes.bool,
  errorMessage: PropTypes.string,
  uploadProgress: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

Button.defaultProps = {
  isLoading: false,
  loginSuccess: false,
  errorMessage: '',
  uploadProgress: 0,
  disabled: false,
  children: 'Create Post'
};

export default Button;
