import { 
  REGISTER_SUCCESS, 
  LOGIN_SUCCESS, 
  LOGOUT, 
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_SUCCESS, 
  UPDATE_EMAIL,
  VERIFY_CODE_REQUEST,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILURE,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  SET_USER_ID
} from './actionTypes';

const initialState = {
  isAuthenticated: !!localStorage.getItem('jwtToken'), // Initialize isAuthenticated based on the token
  token: localStorage.getItem('jwtToken') || null,
  verificationCodeSent: false,
  userDetails: null,
  email: '',
  user: null,
  userId: null, // Initialize userId to null
  loading: false,
  error: null,
  
  //isAuthenticated: false,
  //token: null,
  //userActive: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        email: action.payload,
      };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          token: action.payload,
          userId: action.payload.userId, // Set userId from the payload
        };
        case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
      case FETCH_USER_DETAILS_SUCCESS:
        return {
          ...state,
          userDetails: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_USER_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: 'Failed to fetch user details',
        };
      case LOGOUT:
        return {
          ...state,
          isAuthenticated: false,
          token: null,
          userDetails: null, // Clear user details on logout
        };
      case FORGOT_PASSWORD_SUCCESS:
        return { ...state, verificationCodeSent: true };
      case UPDATE_PASSWORD_SUCCESS:
        return { ...state, verificationCodeSent: false };
        case VERIFY_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        verificationCodeSent: true, // Update state to indicate code is sent
      };
    case VERIFY_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
    //case SET_USER_ACTIVE:
     // return {
     //   ...state,
    //    userActive: action.payload,
    //  };
      case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload, // Update the email state with the new email
      };
    // Other cases for different action types...
    default:
      return state;
  }
};

export default authReducer;
