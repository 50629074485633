import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; 
import { API_URL } from '../../config';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import UserEditForm from './UserEditForm'; 
import useDebounce from '../../utils/useDebounce';
import SpinnerArewaLogo from '../SpinnerArewaLogo';
// import { PostDetailsDiv, AudioIndicator, ActiveStatusAndEllipsesDiv, Actions, AdminActiveStatusDiv, 
//   StyledTime, Ellipses, DropdownHeader, DropdownMenu, DropdownOption, CloseIcon, } from '../ProducerAllPosts';
import { MdClose } from 'react-icons/md';
import { IoMdOptions } from "react-icons/io";

const SpinnerArewaLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transaparent;
  min-height: 100vh;
`;

const FormContainer = styled.div`
    position: relative;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const FormTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const Tabs = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    border-radius: 10px;
`;

const Tab = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    background: ${props => props.active ? props.theme.colors.primary : '#ddd'};
    color: ${props => props.active ? 'white' : 'black'};
    cursor: pointer;
    &:hover {
        background: #007bff;
        color: white;
    }
`;

const PlanCard = styled.div`
  background-color: ${(props) => props.theme.colors.background2};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
`;

const ServiceTypediv = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 5px;
`;

const UserList = styled.div`
    margin-bottom: 20px;
`;

const PostDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;


const ActiveStatusAndEllipsesDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const AdminActiveStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(props) => {
    if (props.adminStatus === 'active') return '#d4edda';
    if (props.adminStatus === 'inactive') return '#fff3cd';
    if (props.adminStatus === 'blocked') return '#f8d7da';
    return '#f0f0f0';
  }};
  color: ${(props) => {
    if (props.adminStatus === 'active') return '#155724';
    if (props.adminStatus === 'inactive') return '#856404';
    if (props.adminStatus === 'blocked') return '#721c24';
    return '#333';
  }};
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledTime = styled.time`
  color: ${(props) => props.theme.colors.text};
`;

const Ellipses = styled.div`
  cursor: pointer;
  font-size: 24px;
  font-weight: 800;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: #007bff;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  background: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.text};
  overflow: hidden;
  z-index: 10;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.colors.dark};
  color: ${props => props.theme.colors.text};
  padding: 10px;
`;

const CloseIcon = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: ${(props) => props.theme.colors.danger};
  &:hover {
    color: #ff0000;
  }
`;

const DropdownOption = styled.div`
  padding: 10px 15px;
  background: ${props => props.theme.colors.background2};
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  //white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const SearchAndFilter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  color: ${(props) => props.theme.colors.text};
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }
  button {
    padding: 10px;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.text};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SearchAndFilterIconDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text};

  input {
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid #ccc;
    color: ${(props) => props.theme.colors.text};
  }

`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: ${props => (props.postIsActive ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 6px;
    height: 6px;
  }
`;


const Users = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const maxVisiblePages = 5;
    const [counts, setCounts] = useState({ allUsers: 0, authors: 0, blockedUsers: 0 });
    const [tab, setTab] = useState('all');
    const [showFilter, setShowFilter] = useState(false);
    const [dropdownPostId, setDropdownPostId] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 500); // 500ms debounce delay

    const handleShowFilter = () => {
      setShowFilter(!showFilter);
    };

    const fetchUsers = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/users/getAll`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setUsers(response.data.users);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, debouncedSearch, tab, navigate]);

    const fetchCounts = useCallback(async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          };
          const response = await axios.get(`${API_URL}/users/counts`, {
            params: { search: debouncedSearch, },
            ...config
          });
          setCounts(response.data);
        } catch (error) {
          console.error('Error fetching user counts:', error);
        }
      }
    }, [debouncedSearch, navigate]);

    useEffect(() => {
      fetchCounts();
      fetchUsers();
  }, [currentPage, debouncedSearch, tab, navigate, fetchUsers, fetchCounts]);

  const handleDelete = async (userId) => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    if (window.confirm('Are you sure you want to delete this ad?')) {
      try {
        await axios.delete(`${API_URL}/admin/ad/delete/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }); 
        setUsers(users.filter(user => user.id !== userId)); 
        setIsDropdownOpen(false);
      } catch (error) {
        setIsDropdownOpen(false);
        console.error('Error deleting ad:', error);
      }
    }
  }
  };

    const handleSelectUser = (user) => {
        if (selectedUser && selectedUser.id === user.id) {
            setSelectedUser(null);
        } else {
            setSelectedUser(user);
        }
    };

    const handleCloseUser = () => {
        setSelectedUser(null);
    };

    const handleDropdownClick = (adId) => {
      if (dropdownPostId === adId && isDropdownOpen) {
        setIsDropdownOpen(false);
        setDropdownPostId(null);
      } else {
        setIsDropdownOpen(true);
        setDropdownPostId(adId);
      }
    };

    const handleUpdatePostStatus = (adId) => {
      setDropdownPostId(null);
      setIsDropdownOpen(false);
    };
  
    const handleCloseDropdown = () => {
      setDropdownPostId(null);
      setIsDropdownOpen(false);
    };

    const scrollToTop = () => {
      ListRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const handlePageClick = (page) => {
      setCurrentPage(page);
      scrollToTop();
  };

  const handleNextPage = () => {
      if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          scrollToTop();
      }
  };

  const handlePreviousPage = () => {
      if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scrollToTop();
      }
  };

  const handleTabChange = (tab) => {
      setTab(tab);
      setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const pad = (num) => (num < 10 ? `0${num}` : num);
  
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
  
    let hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    return `${day}/${month}/${year} ${pad(hours)}:${minutes}:${seconds} ${ampm}`;
  };

  const formatWallet = (wallet) => {
    // Check if wallet is a valid number or string that can be converted to a number
    if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
      return 'Invalid earnings';
    }

    // Convert earnings to number and ensure it's a valid number
    wallet = parseFloat(wallet);
    if (isNaN(wallet) || !isFinite(wallet)) {
      return 'Invalid wallet';
    }

    // Convert earnings to string with 2 decimal places
    let walletStr = wallet.toFixed(2);

    // Split wallet into whole and decimal parts
    const parts = walletStr.split('.');
    let wholePart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Add commas for thousands, millions, etc.
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    wholePart = wholePart.replace(regex, ',');

    // Return formatted earnings
    return wholePart + decimalPart;
  };

  const generateVisiblePageNumbers = () => {
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

    if (isLoading) {
      return (
          <SpinnerArewaLogoDiv>
          <SpinnerArewaLogo />
          </SpinnerArewaLogoDiv>
      )
    }

    return (
        <FormContainer>
            <FormTitle>All Users</FormTitle>
            <Tabs>
              <Tab active={tab === 'all'} onClick={() => handleTabChange('all')}>
                All Users ({counts.allUsers})
              </Tab>
              <Tab active={tab === 'authors'} onClick={() => handleTabChange('authors')}>
                Authors ({counts.authors})
              </Tab>
              <Tab active={tab === 'blocked'} onClick={() => handleTabChange('blocked')}>
                Blocked Users ({counts.blockedUsers})
              </Tab>
            </Tabs>

          <SearchAndFilter>
                <SearchAndFilterIconDiv>
                    <Input
                      type="text"
                      placeholder="Search by username or email"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    <div onClick={handleShowFilter}><IoMdOptions size={30} width={100} height={100} /></div>
                  </SearchAndFilterIconDiv>
                  {showFilter && (
                    <>
              
                    </>
                  )}
            </SearchAndFilter> 

            <UserList ref={ListRef}>
                {users.map(user => (
                  <PlanCard key={user.id}>
                  {/* <ThumbnailDiv onClick={() => handleSelectUser(user)}>
                      {user.profile_picture && <img src={`${baseURL}${user.profile_picture}`} alt="Thumbnail" />}
                  </ThumbnailDiv> */}
                  <PostDetailsDiv>
                      <ServiceTypediv>Name: {user.first_name} {user.last_name}</ServiceTypediv>
                  <div onClick={() => handleSelectUser(user)}>
                      <p>Wallet: ₦{formatWallet(user.wallet)}</p>
                      <p>{user.phone}</p>
                      <StyledTime>Last Seen On: {formatDate(user.last_activity)}</StyledTime>
                  </div>
                  <ActiveStatusAndEllipsesDiv>
                      <AdminActiveStatusDiv adminStatus={user.active_status}>
                      <p>{user.active_status}</p> 
                      {user.active_status === 'active' && <AudioIndicator postIsActive={user.active_status}/>}
                      </AdminActiveStatusDiv>
                      <Ellipses onClick={() => handleDropdownClick(user.id)}>
                      <IoMdOptions />
                      </Ellipses>
                  </ActiveStatusAndEllipsesDiv>
                  </PostDetailsDiv>
                  <Actions ref={dropdownRef}>
                  {isDropdownOpen && dropdownPostId === user.id ? (
                      <DropdownMenu onClick={(e) => e.stopPropagation()}>
                      <DropdownHeader>
                          <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                      </DropdownHeader>
                      <DropdownOption onClick={() => handleSelectUser(user)}>Edit</DropdownOption>
                      <DropdownOption onClick={() => handleDelete(user.id)}>Delete</DropdownOption>
                      <DropdownOption onClick={() => handleUpdatePostStatus(user.id)}>Update Status</DropdownOption>
                      </DropdownMenu>
                  ) : null}
                  </Actions>
                  </PlanCard>
                ))}
            </UserList>
            {totalPages > 1 && (
                <PaginationWrapper>
                <Pagination>
                  <PaginationButton
                    onClick={() => setCurrentPage(1)} // Jump to first page
                    disabled={currentPage === 1}
                  >
                    First
                  </PaginationButton>
          
                  <PaginationButton
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </PaginationButton>
          
                  {generateVisiblePageNumbers().map((page) => (
                    <PageNumber
                      key={page}
                      onClick={() => handlePageClick(page)}
                      className={currentPage === page ? 'active' : ''}
                    >
                      {page}
                    </PageNumber>
                  ))}
          
                  <PaginationButton
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </PaginationButton>
          
                  <PaginationButton
                    onClick={() => setCurrentPage(totalPages)} // Jump to last page
                    disabled={currentPage === totalPages}
                  >
                    Last
                  </PaginationButton>
                </Pagination>
              </PaginationWrapper>
            )}
            {selectedUser && (
                <>
                    <ModalBackdrop onClick={handleCloseUser} />
                    <FormModelDiv>
                        <UserEditForm
                            user={selectedUser}
                            onClose={handleCloseUser}
                            refresh={fetchUsers}
                            refreshCounts={fetchCounts}
                        />
                    </FormModelDiv>
                </>
            )}
        </FormContainer>
    );
};

export default Users;
