import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../../config';
import { getValidToken } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import Button from '../Button'; 

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: ${props => props.theme.colors.background};
`;

const ContentSection = styled.div`
  background: ${props => props.theme.colors.background2};
  border-radius: 15px;
  padding: 10px;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
`;

const InputBox = styled.div`
display: flex;
justify-content: center;
position: relative;
width: 100%;
height: 50px;
border: 1px solid ${(props) => props.theme.colors.text};
border-radius: 6px;
margin-bottom: 25px;

&:nth-child input:valid ~ span,
&:nth-child input:focus ~ span {
  background: #00dfc4;
  color: black;
  border-radius: 2px;
}

input:valid ~ span,
input:focus ~ span {
  color: black;
  transform: translateX(10px) translateY(-14px);
  font-size: 15px;
  padding: 0 10px;
  background: #32a852;
  border-left: 1px solid #00dfc4;
  border-right: 1px solid #00dfc4;
  border-radius: 5px;
}
`;

const Label = styled.span`
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text};
  transition: 0.3s ease;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

// FormControl component styled with theme variables
const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 6px;
  outline: none;
  color: ${props => props.theme.colors.text};
  font-size: 16px;
  transition: 0.3s;

  &:valid,
  &:focus {
    border: 2px solid #00dfc4;
  }
`;

const TextArea = styled.textarea`
  padding: 8px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const AdminWalletManagement = () => {
  const [userData, setUserData] = useState({ emailOrPhone: '', action: 'credit', amount: '', reason: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successfull, setSuccessFull] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateWallet = async (e) => {
    e.preventDefault();
    const token = await getValidToken(navigate, true);
    if (!token) return;
    setIsLoading(true);
    setSuccessFull(false);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(`${API_URL}/admin/wallet-management`, userData, config);
      setSuccessMessage(response.data.message);
      setIsLoading(false);
      setSuccessFull(true);
      setError('');

      setTimeout(() => {
        setSuccessMessage('');
        setSuccessFull(false);
        setUserData({ emailOrPhone: '', action: 'credit', amount: '', reason: '' });
      }, 4000);
    } catch (error) {
      console.error('Error updating wallet:', error);
      setError(error.response?.data?.error || 'An error occurred.');
      setSuccessMessage('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Heading>Admin Wallet Management</Heading>
      <ContentSection>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <InputBox>
      <StyledInput
        type="text"
        name="emailOrPhone"
        placeholder="User Email or Phone"
        value={userData.emailOrPhone}
        onChange={handleInputChange}
        required
      />
      <Label>User Email or Phone</Label>
      </InputBox>

      <InputBox>
      <Select name="action" value={userData.action} onChange={handleInputChange} required>
        <option value="credit">Credit</option>
        <option value="debit">Debit</option>
      </Select>
      </InputBox>

      <InputBox>
      <StyledInput
        type="number"
        name="amount"
        placeholder="Amount"
        value={userData.amount}
        onChange={handleInputChange}
        required
      />
      <Label>Amount</Label>
      </InputBox>

      <TextArea
        name="reason"
        placeholder="Reason"
        value={userData.reason}
        onChange={handleInputChange}
      />

      <Button onClick={handleUpdateWallet} isLoading={isLoading} isP={true} disabled={isLoading} errorMessage={error} loginSuccess={successfull}>
        Update Wallet
      </Button>
      </ContentSection>
    </Container>
  );
};

export default AdminWalletManagement;