//const API_URL = 'http://localhost:5000/api'; //172.20.10.12:5000/api
const API_URL = 'https://arewamega.com/api'; // Change this URL based on your API server

const API_UPLOADS_URL = 'http://localhost:5000';

const bucketName = 'kasuwa-app.appspot.com'; // Your Firebase Storage bucket name
const baseURL = `https://storage.googleapis.com/${bucketName}/`;

export { API_URL, API_UPLOADS_URL, baseURL };
 
