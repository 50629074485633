import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
`;

const TermsTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const TermsText = styled.p`
    margin-bottom: 10px;
    line-height: 1.6;
    color: ${props => props.theme.colors.text};
`;


const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const TermsAndConditionsForChannelRequest = ({ onClose, iAgree }) => {

    const handleIagree = () => {
        iAgree();
        onClose();
    }

    return (
        <TermsContainer>
            <TermsTitle>Terms and Conditions</TermsTitle>
            <TermsText>
                By requesting to create a channel, you agree to the following terms and conditions:
            </TermsText>
            <TermsText>
                1. No copyrighted materials are allowed to be uploaded or streamed through your channel.
            </TermsText>
            <TermsText>
                2. For each sale made through your channel, you will receive 70% of the revenue.
            </TermsText>
            <TermsText>
                3. You are responsible for ensuring that all content on your channel adheres to applicable laws and regulations.
            </TermsText>
            <TermsText>
                4. Our platform reserves the right to remove any content that violates these terms or any applicable laws.
            </TermsText>
            <TermsText>
                5. For streaming and e-commerce activities, you must comply with additional guidelines provided by our platform.
            </TermsText>
            
            <ButtonsDiv>
            <Button onClick={handleIagree}>
                I Agree 
            </Button>
            <Button onClick={onClose} style={{ background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </TermsContainer>
    );
};

export default TermsAndConditionsForChannelRequest;
