import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getValidToken } from './auth'; // Adjust the import path accordingly

const UserAuthCheck = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = await getValidToken(navigate, true);
      console.log('Checking authentication...', token);
      if (!token) {
        console.log('No valid token found, navigating to login...');
        navigate('/login');
      } else {
        console.log('Valid token found:', token);
      }
    };

    checkAuth();
  }, [navigate]);

  return children;
};

export default UserAuthCheck;
