import React, { createContext, useState, useEffect } from 'react';

const SoundContext = createContext();

const SoundProvider = ({ children }) => {
  const [audioContext, setAudioContext] = useState(null);

  const initializeAudioContext = () => {
    if (!audioContext) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      setAudioContext(new AudioContext());
    }
  };

  useEffect(() => {
    // Ensure the audio context is created only once
    const handleUserInteraction = () => {
      initializeAudioContext();
      document.removeEventListener('click', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);
    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  return (
    <SoundContext.Provider value={{ audioContext }}>
      {children}
    </SoundContext.Provider>
  );
};

export { SoundContext, SoundProvider };
